import Swal from 'sweetalert2'

export default class ConfirmOnClick extends HTMLAnchorElement {

    constructor() {
        super();
    }

    connectedCallback() {
        this.addEventListener("click", e => {
            e.preventDefault();
            let self = this;
            Swal({
                animation: false,
                customClass: 'animated fadeIn',
                title: this.dataset.swalTitle,
                html: this.dataset.swalText,
                type: this.dataset.swalType || 'warning',
                showCancelButton: this.dataset.swalCancelButton || true,
                confirmButtonText: this.dataset.swalConfirm,
                cancelButtonText: this.dataset.swalCancel,
            }).then(function (result) {
                if (result.value) {
                    window.location.href = self.href;
                }
            });
        });
    }
}