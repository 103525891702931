import Swal from 'sweetalert2'

export default class FrontNewsAddComment extends HTMLDivElement {

    constructor() {
        super();
    }

    refreshView(data) {
        if (data.success == false) {
            toastr.error('Une erreur s\'est produite.')
        } else {
            if (data.hasOwnProperty('view')) {
                document.getElementById('news-v2-front-comments-list').innerHTML = data.view;
            }
            if (data.hasOwnProperty('message')) {
                Swal('', data.message, 'success');
            }
        }
    }
}