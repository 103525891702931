import ContactUs from './components/contact-us';
customElements.define('contact-us', ContactUs,{extends: 'div'});

import MyProfileCard from './components/MyProfileCard';
customElements.define('my-profile-card', MyProfileCard,{extends: 'div'});

import RevealPassword from './components/RevealPassword';
customElements.define('reveal-password', RevealPassword, {extends: 'a'});

import AjaxSwitch from './components/AjaxSwitch';
customElements.define('ajax-switch', AjaxSwitch, {extends: 'input'});

import SingleButtonFileInput from './components/SingleButtonFileInput';
customElements.define('single-button-file-input', SingleButtonFileInput, {extends: 'input'});

import SelectDecli from './components/select-decli';
customElements.define('select-decli', SelectDecli, {extends: 'select'});

import WysiwygReveal from './components/WysiwygReveal';
customElements.define('wysiwyg-reveal', WysiwygReveal, {extends: 'div'});

import BlocActus from './components/BlocActus';
customElements.define('bloc-actus', BlocActus, {extends: 'div'});

import ReadMoreOpenScroll from './components/ReadMoreOpenScroll';
customElements.define('read-more', ReadMoreOpenScroll, {extends: 'a'});

import ProductPack from './components/ProductPack';
customElements.define('product-pack', ProductPack, {extends: 'div'});

import ProductPackLight from './components/ProductPackLight';
customElements.define('product-pack-light', ProductPackLight, {extends: 'div'});

import PreventAddToCart from "./components/PreventAddToCart";
customElements.define('prevent-add-to-cart', PreventAddToCart, {extends: 'div'});

import PreventAddToCartList from "./components/PreventAddToCartList";
customElements.define('prevent-add-to-cart-list', PreventAddToCartList, {extends: 'div'});
