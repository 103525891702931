import updateCartViews from "hegyd/ecommerce-v2/resources/assets/tabler/js/frontoffice/eCommerce";
import swal from "sweetalert2";
import LinkToCart from "./LinkToCart";
require('select2');

export default class CartSteps extends HTMLDivElement {
    connectedCallback() {
        this.initEvents();
        this.linkToCart = new LinkToCart();
        this.currentStep = this.dataset.currentStep ? this.dataset.currentStep : 1;
    }

    getSelectorInputQuantity() {
        return 'input[name="product-count"]';
    }

    getSelectorRemoveCartLine() {
        return 'a.order-item-card-remove';
    }

    getSelectorCartLine() {
        return '.order-item-card';
    }

    getSelectorResetCart() {
        return 'a#reset_cart';
    }

    getSelectorValueCodePromo() {
        return 'input[name="promocode"]';
    }

    getElementAddCodePromo() {
        return $(this.getSelectorValueCodePromo());
    }

    getSelectorRemoveCodePromo() {
        return 'a#remove-cart';
    }

    getElementRemoveCodePromo() {
        return $(this.getSelectorRemoveCodePromo());
    }

    getSelectorApplyCodePromo() {
        return '.promo-code-input button';
    }

    getAllCartTemplate() {
        return ['cart-container'];
    }

    getSelectorCheckboxCGV() {
        return '#accept_cgv';
    }

    getNoValideCodeElement() {
        return $('#no_valide_code');
    }

    getElementLoaderModal() {
        return $('.loading');
    }

    getSelectorCartValidation() {
        return '#cart-validate';
    }

    getSelectorChooseAddress() {
        return 'a.choose-address';
    }

    getSelectorEditAddress() {
        return 'a.add-address, a.edit-address';
    }

    getSelectorChangeShippingMeans() {
        return 'input[name="shipping_means"]';
    }

    getSelectorChangePaymentMeans() {
        return 'input[name="payment_means"]';
    }

    getSelectorMoneticoForm() {
        return 'form#monetico-pay-one-time, form#monetico-pay-three-times, form#monetico-pay-four-times';
    }

    hideLoader() {
        this.getElementLoaderModal().hide();
    }

    reloadCart() {
        HegydCore.sendRequest(this.dataset.routeRefresh, 'GET', {
            return_templates: this.getAllCartTemplate(),
            current_step: this.currentStep,
        }, (data) => {
            this.updateCartViews(data);
        });
    }

    updateProductQuantity($input_quantity) {
        HegydCore.sendRequest($input_quantity.data('route'), 'PUT', {
            product_id: $input_quantity.parents(this.getSelectorCartLine()).data('product_id'),
            user_id: $input_quantity.parents(this.getSelectorCartLine()).data('user_id'),
            quantity: $input_quantity.val(),
            return_templates: ['cart-sidebar'],
            current_step: this.currentStep,
        }, (data) => {
            this.updateCartViews(data);
            this.refreshCartIcon();
        });
    }

    deleteProduct($remove_cross) {
        this.askToDelete($remove_cross.data('message'), $remove_cross.data('button'),(callback) => {
            HegydCore.sendRequest($remove_cross.attr('href'), 'DELETE', {
                product_id: $remove_cross.parents(this.getSelectorCartLine()).data('product_id'),
                user_id: $remove_cross.parents(this.getSelectorCartLine()).data('user_id'),
                return_templates: this.getAllCartTemplate(),
                current_step: this.currentStep,
            }, (data) => {

                let dataLayerDatas = JSON.parse($remove_cross.find('.datalayer-data').html());
                window.dataLayer = window.dataLayer || [];

                dataLayer.push({ ecommerce: null });
                dataLayer.push(dataLayerDatas);

                this.updateCartIndexView(data);
                this.refreshCartIcon();
                if (callback) {
                    callback();
                }
            });
        });
    }

    updateCartIndexView(data) {
        this.updateCartViews(data);
        swal.close();
    }

    updateCartViews(data) {
        updateCartViews(data);
    }

    askToDelete(deleteText, buttonText, callback) {
        swal({
            title: '',
            html: deleteText + "</span>",
            allowOutsideClick: true,
            type: 'warning',
            showCancelButton: true,
            closeOnConfirm: false,
            closeOnCancel: true,
            confirmButtonColor: '#ee0000',
            cancelButtonText: 'Annuler',
            confirmButtonText: buttonText,
            showLoaderOnConfirm: true,
            preConfirm: function () {
                return new Promise(function (resolve) {
                    if (callback) {
                        callback(resolve);
                    }
                });
            }
        }).then(function () {
            swal.close();
        });
    }

    deleteCart($link_reset_cart) {
        this.askToDelete($link_reset_cart.data('message'), $link_reset_cart.data('btn_text'), callback => {
            HegydCore.sendRequest($link_reset_cart.attr('href'), 'PUT', {
                return_templates: this.getAllCartTemplate(),
                current_step: this.currentStep,
            }, (data) => {
                this.updateCartIndexView(data);
                if (callback) {
                    callback();
                }
            });
        });
    }

    selectDeliveryMode($input_delivery_mode) {
        HegydCore.sendRequest($input_delivery_mode.data('route'), 'PUT', {
            shipping_means: $input_delivery_mode.val(),
            'update-cart': true,
            return_templates: ['cart-sidebar', 'cart-shipping-means'],
            current_step: this.currentStep,
        }, (data) => {
            this.updateCartViews(data);
        });
    }

    selectPaymentMean($select_payment_mean) {
        HegydCore.sendRequest($select_payment_mean.data('route'), 'PUT', {
            payment_means: $select_payment_mean.val(),
            'update-cart': true,
            return_templates: ['cart-sidebar', 'cart-payment-means'],
            current_step: this.currentStep,
        }, (data) => {
            this.updateCartViews(data);
        });
    }

    toggleValidationCGV($element) {
        HegydCore.sendRequest($element.data('route'),'PUT', {
            'update-cart': true,
            accept_cgv: $element.prop('checked') ? 1 : 0,
            return_templates: ['cart-sidebar'],
            current_step: this.currentStep,
        }, (data) => {
            this.updateCartIndexView(data);
        });
    }

    applyCodePromo() {
        const code = this.getElementAddCodePromo().val()
        if (code.length === 0) {
            this.getNoValideCodeElement().show();
            return false;
        }
        HegydCore.sendRequest(this.getElementAddCodePromo().data('route'), 'POST', {
            code: code,
            return_templates: ['cart-sidebar'],
            current_step: this.currentStep,
        }, (data) => {
            this.updateCartViews(data);
        }, (data) => {
            this.getNoValideCodeElement().empty().html(data.responseJSON.text).show();
        });
    }

    removeCodePromo() {
        HegydCore.sendRequest( this.getElementRemoveCodePromo().attr('href'), 'DELETE', {
            return_templates: this.getAllCartTemplate(),
            current_step: this.currentStep,
        }, (data) => {
            this.updateCartViews(data);
        });
    }

    updateQuantity( $element, $operation )
    {
        let $quantity_element = $element.parents('.order-item-card').find( this.getSelectorInputQuantity() );
        let val = parseInt( $quantity_element.val() );
        if($operation === "+"){
            val = val + 1
        }else{
            if(val > 1){
                val = val - 1
            }
        }
        $quantity_element.val(val);
        $quantity_element.trigger('change');
    }

    /* Init all cart events */
    initEvents() {
        /* Changement de la quantité d'une ligne */
        $(this).on('change', this.getSelectorInputQuantity(), (e) => {
            this.updateProductQuantity( $(e.target) )
        });

        /* Suppression d'une ligne du panier */
        $(this).on('click', this.getSelectorRemoveCartLine(), (e) => {
            e.preventDefault();
            this.deleteProduct( $(e.target).closest( this.getSelectorRemoveCartLine() ) );
        });

        /* Remise à zéro du panier */
        $(this).on('click', this.getSelectorResetCart(), (e) => {
            e.preventDefault();
            this.deleteCart($(e.target).closest(this.getSelectorResetCart()));
        });

        /* Validation des CGV */
        $(this).on('change', this.getSelectorCheckboxCGV(), (e) => {
            e.preventDefault();
            this.toggleValidationCGV($(e.target));
        });

        /* Ajout d'un code promotion */
        $(this).on('click', this.getSelectorApplyCodePromo(), (e) => {
            e.preventDefault();
            this.applyCodePromo();
        });

        /* Suppression d'un code promotion */
        $(this).on('click', this.getSelectorRemoveCodePromo(), (e) => {
            e.preventDefault();
            this.removeCodePromo();
        });

        /* Choix d'une adresse */
        $(this).on('click', this.getSelectorChooseAddress(), (e) => {
            e.preventDefault();
            this.initCartAddressesModal($(e.target).closest(this.getSelectorChooseAddress()).attr('href'));
        });

        /* Ajout/Edition d'une adresse */
        $(this).on('click', this.getSelectorEditAddress(), (e) => {
            e.preventDefault();
            this.initCartAddressesModal($(e.target).closest(this.getSelectorEditAddress()).attr('href'), () => {
                this.initSelect2Countries();
                this.hideLoader();
            });
        });

        /* Choix d'un mode de livraison */
        $(this).on('change', this.getSelectorChangeShippingMeans(), (e) => {
            this.selectDeliveryMode($(e.target));
        });

        /* Sélection d'un moyen de paiement */
        $(this).on('change', this.getSelectorChangePaymentMeans(), (e) => {
            this.selectPaymentMean($(e.target));
        });

        /* Ajout/Edition d'une adresse */
        $(this).on('click', this.getSelectorCartValidation(), (e) => {
            e.preventDefault();

            // Envoie du formulaire
            const dataLayerDatas = JSON.parse($('.payment-datalayer-data').html());
            window.dataLayer = window.dataLayer || [];

            dataLayer.push({ ecommerce: null });
            dataLayer.push(dataLayerDatas);

            window.location = $(e.target).attr('href');
        });

        /* Soumission formulaire Monetico */
        $(this).on('submit', this.getSelectorMoneticoForm(), (e) => {
            e.preventDefault();

            // Désactive le bouton de soumission
            $(this)
                .find('button[form="'+$(e.target).attr('id')+'"]')
                .attr('disabled', true);

            // Envoie du formulaire
            const dataLayerDatas = JSON.parse($('.payment-datalayer-data').html());

            window.dataLayer = window.dataLayer || [];

            dataLayer.push({ ecommerce: null });
            dataLayer.push(dataLayerDatas);

            // Lance en ajax la requête préalable pour changer le statut du panier
            const beforeSubmitRequest = new Promise((resolve, reject) => {
                HegydCore.sendRequest(
                    $(e.target).data('route'),
                    'POST',
                    {},
                    (data) => {
                        resolve();
                    },
                    (data) => {
                        reject();
                    }
                );
            });

            // Après confirmation de la requête préalable, soumet le formulaire
            beforeSubmitRequest.then(
                () => {
                    // Soumission du formulaire
                    e.target.submit();
                },
                () => {
                    toastr.error('Une erreur s\'est produite');
                }
            );
        });
    }

    refreshCartIcon() {
        this.linkToCart.refresh();
    }

    initCartAddressesModal(url, loadedCallback) {
        HegydCore.initRemoteModal('#modal-cart-steps', url, loadedCallback, (data) => {
            setTimeout(() => {
                this.updateCartViews(data);
                toastr.success('L\'adresse a été mise à jour !');
                window.location.reload();
            }, 300);
        });
    }

    initSelect2Countries() {
        $('#country_id').select2({
            language: 'fr',
            ajax: {
                url: '/countries',
                data: function (params) {
                    return {
                        query: params.term
                    };
                },
                processResults: function (data) {
                    const results = [];
                    $.each(data, function (id, entity) {
                        results.push({
                            id: entity.id,
                            text: entity.title_fr
                        });
                    });
                    return {results: results}
                },
                templateResult: function (data) {
                    return data.title_fr;
                }
            }//,
            // initSelection: function (element, callback) {
            //     const id = $('[name=address_country_id]').val();
            //     const results = [];
            //     if (id) {
            //         $.ajax("/countries/" + id, {
            //             dataType: "json"
            //         }).done(function (entity) {
            //             if (entity) {
            //                 const id = entity.id;
            //                 const text = entity.title_fr;
            //                 results.push({id: id, text: text});
            //             }
            //             callback(results);
            //         });
            //     }
            //     callback(results);
            // }
        });
    }

};
