import {Modalator} from 'hegyd/core/resources/assets/js/utils/Modalator.js'

export default class ContactUs extends HTMLDivElement {

    constructor() {
        super();
    }
    
    connectedCallback() {
        var self = this;
        $(document).on('click', '#open-contact-form', e => {
            self.openContactForm();
        });
    }

    openContactForm() {
        const reCaptchaSiteKey = process.env.MIX_RECAPTCHA_V3_SITE_KEY;
        const modalId = '#contact-form-modal';
        const $modal = $(modalId);
        var modalator = new Modalator();
        modalator.initRemoteModal(
            modalId, 
            $modal.data('href'),
            function (){
                //On génère le recaptcha du champs qui apparait dans la modal
                var recaptchaField;
                recaptchaField = grecaptcha.render(document.getElementById('contact_us_id'), {
                    'sitekey': reCaptchaSiteKey ? reCaptchaSiteKey : '6Ldc4ScpAAAAAFB6g3inAF-87uAgjViihruZLC2p',
                    'size': 'invisible',
                    'hl': 'en'
                });
                grecaptcha.ready(function () {
                    grecaptcha.execute(recaptchaField, {
                        action: 'contact_us_action',
                    }).then(function(token) {
                        $('#g-recaptcha-response').val(token);
                    });
                });
            },
            (data) => {
                if(data.redirect){
                    window.location.replace(data.redirect);
                }
            },
            () => {
                 toastr.error('Une erreur s\'est produite');
            }
        );
    }
}