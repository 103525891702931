import ProductFilter from './frontoffice/ProductFilter';
customElements.define('product-filter', ProductFilter,{extends: 'div'});

import AddToCart from './frontoffice/AddToCart';
customElements.define('add-to-cart', AddToCart,{extends: 'button'});

import ProductImagePhotoswipe from './frontoffice/ProductImagePhotoswipe';
customElements.define('product-image-photoswipe', ProductImagePhotoswipe,{extends: 'div'});

import MultiAddToCart from './frontoffice/MultiAddToCart';
customElements.define('multi-add-to-cart', MultiAddToCart,{extends: 'button'});
