export default class SavOrderChange extends HTMLSelectElement {
    constructor() {
        super();
    }

    connectedCallback() {
        $(this).on('change', function (e) {
            this.requestProducts();
        });
    }

    requestProducts() {
        let orderId = document.querySelector('select[name="order_id"]').value;
        let url = this.getAttribute('data-href');

        HegydCore.sendRequest(url, 'POST', {id:orderId}, function (data) {
            if (data.hasOwnProperty('item')) {
                $('#products-list').empty().html(data.item);
            }else{
                toastr.error(data.errorMessage);
            }
        },function (data) {
            if (data.hasOwnProperty('errorMessage')) {
                toastr.error(data.errorMessage);
            }
        });
    }
}