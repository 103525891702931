export default class CopyLinkToClipboard extends HTMLAnchorElement {

    constructor() {
        super();
    }

    connectedCallback() {
        this.addEventListener("click", function() {
            navigator.clipboard.writeText(this.dataset.copylink).then(
                function () {
                    toastr.success("Le lien est copié dans votre presse-papiers");
                },
                function () {
                    window.alert("Impossible de copier le lien : " + this.dataset.copylink);
                }
            )
        });
    }
}