export default class WysiwygReveal extends HTMLDivElement {

    constructor() {
        super();
    }

    connectedCallback() {
        $(this).find(".show-more-wysiwyg").on('click', () => {
            $(this).find(".wysiwyg-cropper").toggleClass('show');
            $(this).find(".show-less-wysiwyg").toggleClass('hide');
            $(this).find(".show-more-wysiwyg").toggleClass('hide');
        });

        $(this).find(".show-less-wysiwyg").on('click', () => {
            $(this).find(".wysiwyg-cropper").toggleClass('show');
            $(this).find(".show-less-wysiwyg").toggleClass('hide');
            $(this).find(".show-more-wysiwyg").toggleClass('hide');
        });
    }
}
