export default class PreventAddToCart extends HTMLDivElement {

    constructor() {
        super();
    }

    connectedCallback() {
        this.checkQuantity();
    }

    checkQuantity() {
        document.addEventListener('DOMContentLoaded', function() {
            const addToCartButtonButton = document.querySelector('#add-cart');
            const currentProductId = addToCartButtonButton.dataset.inputQuantityId.replace(/\D/g, '');
            const productCountInput = document.querySelector(`#product-count-${currentProductId}`);
            const addToCartButtonA = document.querySelector('.add-to-cart-btn');
            const addToCartButtonPack = document.querySelector('#add-to-cart');
            const addToCartButtonReponsive = document.querySelector('.offcanvas-product-pack #add-to-cart');
            const addToCartButtonReponsiveFake = document.querySelector('.section-product-pack .responsive-add-to-cart');

            const checkQuantity = () => {
                if (productCountInput.value <= 0) {
                    addToCartButtonA.disabled = true;
                    addToCartButtonA.classList.add('cursor-disabled');
                    addToCartButtonButton.disabled = true;
                    addToCartButtonPack.disabled = true;
                    addToCartButtonReponsive.disabled = true;
                    addToCartButtonReponsiveFake.classList.add('disabled');
                } else {
                    addToCartButtonA.disabled = false;
                    addToCartButtonA.classList.remove('cursor-disabled');
                    addToCartButtonButton.disabled = false;
                    addToCartButtonPack.disabled = false;
                    addToCartButtonReponsive.disabled = false;
                    addToCartButtonReponsiveFake.classList.remove('disabled');
                }
            };

            productCountInput.addEventListener('input', checkQuantity);
            productCountInput.addEventListener('change', checkQuantity);
        });
    }
}
