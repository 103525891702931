export default class FaqLikeDislikeButton extends HTMLAnchorElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.initEvents();
    }

    initEvents() {
        this.addEventListener('click', function(e) {
            e.preventDefault();

            var $this = $(this);

            $.ajax({
                url: $this.attr('href'),
                method: 'put',
                success: function (data) {
                    $this.parents('.like-dislike-block').empty().html(data);

                    toastr.success('Merci de votre participation !');
                }
            });
        });
    }
}