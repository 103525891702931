require('toastr');
require('hegyd/core/resources/assets/js/hegyd-core.js');
require('../../../../../frontoffice/js/hegyd-gtm.js');

import LinkToCart from "../../../ecommerce-v2/js/frontoffice/LinkToCart";

export default class MultiAddToCart extends HTMLButtonElement {
    connectedCallback() {
        // Champ Quantité
        this.inputQuantity = document.querySelector(this.dataset.inputQuantityId);
        this.linkToCart = new LinkToCart();
        // Événements
        this.initEvents();
    }

    /**
     * Événements.
     */
    initEvents() {
        // Click sur le boutton d'ajout au panier
        this.addEventListener('click', (e) => {
            e.preventDefault();
            if ( ! this.disabled) {
                this.addToCart();
            }
        }, true);
    }

    /**
     * Lance la requête d'ajout au panier.
     */
    addToCart() {

        let dataLayerDatas = JSON.parse($(this).find('.datalayer-data').html());
        let dataLayerViewItem = null;
        let dataLayerViewItemCarousel = null;
        if (this.parentElement.id === 'generals-informations' || this.parentElement.id === 'product-pack') {
            dataLayerViewItem = JSON.parse($(this).find('.datalayer-view-item').html());
            dataLayerViewItemCarousel = JSON.parse($(this).find('.datalayer-view-item-carousel').html());
        }
        let qte = 1;
        let productIds = [];

        productIds.push(parseInt(this.getProductIds('parent')));
        productIds.push(parseInt(this.getProductIds('first')));
        productIds.push(parseInt(this.getProductIds('second')));

        dataLayerDatas.ecommerce.items[0].quantity = qte;

        if (dataLayerDatas.ecommerce.items[0].length !== 0) {
            let newItems = [];

            dataLayerDatas.ecommerce.items[0].forEach(function(item) {
                if (productIds.includes(item.product_id)) {
                    delete item.product_id;
                    newItems.push(item);
                }
            });

            dataLayerDatas.ecommerce.items = newItems;
        }

        if (dataLayerViewItem !== null && dataLayerViewItem.ecommerce.items[0].length !== 0) {
            let newItemsView = [];

            dataLayerViewItem.ecommerce.items[0].forEach(function(item) {
                if (!productIds.includes(item.product_id)) {
                    delete item.product_id;
                    newItemsView.push(item);
                }
            });

            dataLayerViewItem.ecommerce.items = newItemsView;
        }

        if (dataLayerViewItemCarousel !== null && dataLayerViewItemCarousel.ecommerce.items[0].length !== 0) {
            dataLayerViewItemCarousel.ecommerce.items[0].forEach(function(item) {
                delete item.product_id;
            });
        }

        window.dataLayer = window.dataLayer || [];

        dataLayer.push({ ecommerce: null });
        dataLayer.push(dataLayerDatas);

        if (dataLayerViewItem !== null) {
            dataLayerViewItem.ecommerce.items = dataLayerViewItem.ecommerce.items.flat();
        }

        if (dataLayerViewItemCarousel !== null) {
            dataLayerViewItemCarousel.ecommerce.items = dataLayerViewItemCarousel.ecommerce.items.flat();
        }

        if ((dataLayerViewItem !== null && dataLayerViewItem.ecommerce.items[0] && dataLayerViewItem.ecommerce.items[0].length !== 0)
            && (dataLayerViewItemCarousel !== null && dataLayerViewItemCarousel.ecommerce.items[0] && dataLayerViewItemCarousel.ecommerce.items[0].length !== 0)) {

            const mergedData = {
                "page": dataLayerViewItem.page,
                "event": dataLayerViewItem.event,
                "ecommerce": {
                    "items": dataLayerViewItem.ecommerce.items.concat(dataLayerViewItemCarousel.ecommerce.items)
                }
            };

            dataLayer.push(mergedData);
        } else if (dataLayerViewItemCarousel !== null && dataLayerViewItemCarousel.ecommerce.items.flat().length === 0
                    && dataLayerViewItem !== null && dataLayerViewItem.ecommerce.items.flat().length !== 0) {
            dataLayer.push(dataLayerViewItem);
        } else if (dataLayerViewItem !== null && dataLayerViewItem.ecommerce.items.flat().length === 0
                    && dataLayerViewItemCarousel !== null && dataLayerViewItemCarousel.ecommerce.items.flat().length !== 0) {
            dataLayer.push(dataLayerViewItemCarousel);
        }

        HegydCore.sendRequest(this.dataset.href, 'POST', {
            product_parent_id: this.getProductIds('parent'),
            product_1_id: this.getProductIds('first'),
            product_2_id: this.getProductIds('second'),
            quantity: qte,
            // return_templates: ['cart-header']
        }, () => {
            this.refreshCartIcon();
        });
    }

    /**
     * Identifiant du produit à ajouter au panier.
     */
    getProductIds(type) {
        let productId;
        if (type === 'parent') {
            productId = this.dataset.inputProductParentId;
        } else if (type === 'first') {
            productId = this.dataset.inputProductLinked1Id;
        } else if (type === 'second') {
            productId = this.dataset.inputProductLinked2Id;
        }
        return productId;
    }

    refreshCartIcon() {
        this.linkToCart.refresh();
    }
}
