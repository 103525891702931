export default class ReadMoreOpenScroll extends HTMLAnchorElement {

    constructor() {
        super();
    }

    connectedCallback() {
        document.addEventListener("DOMContentLoaded", function() {
            document.querySelector('.read-more').addEventListener('click', function(event) {
                event.preventDefault(); // Empêche le comportement par défaut du lien

                // Fait défiler la page jusqu'à l'élément "technicals"
                const technicals = document.getElementById('technicals');
                technicals.scrollIntoView({ behavior: 'smooth' });
            });
        });
    }
}
