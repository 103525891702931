export default class LinkDataForm extends HTMLAnchorElement {

    constructor() {
        super();
    }

    connectedCallback() {
        this.addListeners();
    }

    addListeners() {
        this.addEventListener('click', e => {
            e.preventDefault();
            
            if(! this.querySelector('form'))
            {
                this.appendForm(this);
            }

            this.setupCall(this);
        });
    }

    setupCall(element)
    {
        element.removeAttribute('href');
        element.style.cursor = 'pointer';

        var ajax = element.getAttribute('data-ajax') || false;

        var action = element.getAttribute('data-action') || undefined;
        var containerId = element.getAttribute('data-callback-container-id') || undefined;
        var container;
        var promise;

        if(containerId !== undefined)
        {
            container = document.getElementById(containerId);
        }else{
            container = element;
        }

        var ajaxCall = {
            success: function () {
                if (action !== undefined){
                    container[action](element);
                }
            },
            complete: function (data) {
                if(promise)
                {
                    promise();
                }
            }
        };

        if (element.getAttribute('data-confirm') === undefined || Boolean(element.getAttribute('data-confirm')) === true) {
            var text = element.getAttribute('data-text') || "L'action sera irréversible";

            swal({
                animation: false,
                customClass: 'animated fadeIn',
                title: 'Êtes-vous sûr ?',
                allowOutsideClick: true,
                text: text,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#D2282D",
                cancelButtonText: 'Annuler',
                confirmButtonText: "Oui !",
                showLoaderOnConfirm: true,
                preConfirm: function () {
                    return new Promise(function (resolve) {
                        if (ajax) {
                            promise = resolve;
                            
                            $('form', element).ajaxForm(ajaxCall);
                        }

                        $('form', element).submit();
                    });
                }
            }).then(function () {
                swal.close();
                $('.modal').modal('hide');
            });

        } else {
            if (ajax) {
                $('form', element).ajaxForm(ajaxCall);
            }

            $('form', element).submit();
        }
    }

    appendForm(element){
        var csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

        var form = "<form action='" + element.getAttribute('href') + "' method='POST' style='display:none;'>\n" +
        "<input type='hidden' name='_token' value='" + csrf_token + "' />" +
        "	<input type='hidden' name='_method' value='" + element.getAttribute('data-method') + "'>\n" +
        "</form>"

        element.innerHTML = element.innerHTML + form;
    }

    reloadPage(){
        window.location.reload();
    }
}
