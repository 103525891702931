import Swal from 'sweetalert2'
import {Requestor} from 'hegyd/core/resources/assets/js/utils/Requestor.js'

export default class AjaxOnClick extends HTMLAnchorElement {

    constructor() {
        super();
    }

    connectedCallback() {
        this.addEventListener("click", e => {
            e.preventDefault();

            let route = this.href;
            let metod = this.dataset.method;

            let containerCallback = document;
            let callbackSuccess, callbackError;

            if (this.dataset.callbackContainerId) {
                containerCallback = document.getElementById(this.dataset.callbackContainerId);
            }

            if (this.dataset.successCallback !== undefined) {
                if (this.dataset.successCallback.indexOf("window") === 0) {
                    callbackSuccess = new Function(this.dataset.successCallback);
                } else {
                    callbackSuccess = containerCallback[this.dataset.successCallback];
                }
            }

            if (this.dataset.errorCallback !== undefined) {
                if (this.dataset.errorCallback.indexOf("window") === 0) {
                    callbackError = new Function(this.dataset.errorCallback);
                } else {
                    callbackError = containerCallback[this.dataset.errorCallback];
                }
            }

            if(callbackSuccess === undefined && this.dataset.successRedirect){
                let successRedirect = this.dataset.successRedirect;

                callbackSuccess = function(){
                    window.location.href = successRedirect;
                }
            }

            if (this.dataset.confirm && this.dataset.confirm == "true") {
                let self = this;
                Swal({
                    animation: false,
                    customClass: 'animated fadeIn',
                    title: self.dataset.swalTitle,
                    html: self.dataset.swalText,
                    type: self.dataset.swalType || 'warning',
                    showCancelButton: self.dataset.swalCancelButton || true,
                    confirmButtonText: self.dataset.swalConfirm,
                    cancelButtonText: self.dataset.swalCancel,
                }).then(function (result) {
                    if (result.value) {
                        self.sendAjaxCall(route, metod, callbackSuccess, callbackError);
                    }
                });
            } else {
                this.sendAjaxCall(route, metod, callbackSuccess, callbackError);
            }
        });
    }

    sendAjaxCall(route, method, callbackSuccess, callbackError) {
        var requestor = new Requestor();
        requestor.sendRequest(route, method, {}, callbackSuccess, callbackError);
    }
}