export default class FaqSearch extends HTMLInputElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.initEvents();
    }

    initEvents() {
        this.addEventListener('keydown', function(e) {
            if (event.keyCode == 13) {
                this.parentElement('form').submit();
            }
        });
    }
}