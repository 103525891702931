export default class MyProfileCard extends HTMLDivElement {
    refreshView() {
        window.location.reload();
    }

    showSuccess(data) {
        if (data.message) {
            window.toastr.success(data.message);
        }
    }
}
