export default class RevealPassword extends HTMLAnchorElement {

    constructor() {
        super();
        this.inputId = this.dataset.revealId;
    }

    connectedCallback() {
        this.targetInput = document.getElementById(this.inputId);
        this.watchers();
    }

    watchers() {
        this.addEventListener('click',(e) => {
            e.preventDefault();
            e.stopImmediatePropagation();

            this.toggleTargetType();
        });
    }

    toggleTargetType() {
        this.targetInput.type = this.targetInput.type === 'password' ? 'text' : 'password';
    }
}
