export default class CoreModalVideo extends HTMLDivElement {

    constructor() {

        super();
        this.$video = this.querySelector('video');
    }

    connectedCallback() {
        this.addListeners();
    }

    addListeners() {
        var modal = document.getElementById(this.id);

        // A l'ouverture la modal on lance la vidéo
        modal.addEventListener('shown.bs.modal', function (event) {
            this.$video.play();
            this.$video.currentTime = 0;
        });

        // A la fermeture de la modal on arrête la vidéo
        modal.addEventListener('hide.bs.modal', function (event) {
            this.$video.pause();
            this.$video.currentTime = 0;
        });
    }
}
