import SavOrderChange from './components/pages/backoffice/sav/savOrderChange';
customElements.define('sav-order-change', SavOrderChange,{extends: 'select'});

import SavProductChange from './components/pages/backoffice/sav/savProductChange';
customElements.define('sav-product-change', SavProductChange,{extends: 'select'});

import AddProductButton from './components/pages/backoffice/sav/addProductButton';
customElements.define('sav-add-product', AddProductButton,{extends: 'button'});

import DelProductItem from './components/pages/backoffice/sav/delProductItem';
customElements.define('sav-del-product', DelProductItem,{extends: 'button'});