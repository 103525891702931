export default class GeneratePassword extends HTMLAnchorElement {

    constructor() {
        super();
    }

    connectedCallback() {
        this.addListeners();
    }

    addListeners() {
        this.addEventListener('click', e => {

            var minLength = 8;
            let passKey = document.getElementById('passKey')
            
            if (document.querySelector('input[name="min-length"]')) {
                minLength = document.querySelector('input[name="min-length"]').value;
            }

            var res = this.generatePassword(minLength);
            passKey.innerHTML = "Veuillez recopier le mot de passe généré : <b>" + res + "</b>";
            passKey.style.display = "block";
        });
    }

    // https://stackoverflow.com/questions/1497481/javascript-password-generator
    generatePassword(len) {
        var length = (len) ? (len) : (10);
        var string = "abcdefghijklmnopqrstuvwxyz"; //to upper
        var numeric = '0123456789';
        var punctuation = '!@#$%^&*()_+|}{[]\:;?><,./-=';
        var password = "";
        var character = "";

        while (password.length < length) {
            var entity1 = Math.ceil(string.length * Math.random() * Math.random());
            var entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
            var entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
            var hold = string.charAt(entity1);
            var hold = (password.length % 2 == 0) ? (hold.toUpperCase()) : (hold);

            character += hold;
            character += numeric.charAt(entity2);
            character += punctuation.charAt(entity3);
            password = character;
        }

        password = password.split('').sort(function () {
            return 0.5 - Math.random()
        }).join('');

        return password.substring(0, len);
    }

}

