export default class PreventAddToCartList extends HTMLDivElement {

    constructor() {
        super();
    }

    connectedCallback() {
        this.checkQuantity();
    }

    checkQuantity() {
        const addToCartButtonButton = this.querySelector('#add-cart');
        const currentProductId = addToCartButtonButton.dataset.inputQuantityId.replace(/\D/g, '');
        const productCountInput = this.querySelector(`#quantity-input-${currentProductId}`);
        const addToCartButtonA = this.querySelector('.add-to-cart-btn');

        const checkQuantity = () => {
            if (productCountInput.value <= 0) {
                addToCartButtonA.disabled = true;
                addToCartButtonA.classList.add('cursor-disabled');
                addToCartButtonButton.disabled = true;
            } else {
                addToCartButtonA.disabled = false;
                addToCartButtonA.classList.remove('cursor-disabled');
                addToCartButtonButton.disabled = false;
            }
        };

        productCountInput.addEventListener('input', checkQuantity);
        productCountInput.addEventListener('change', checkQuantity);
    }
}
