export default class DisableOnSubmit extends HTMLButtonElement {

    constructor() {
        super();
    }

    connectedCallback() {
        this.addListeners();
    }

    addListeners() {
        var body = document.querySelector('body');

        body.addEventListener('submit',e => {
            this.disableFormSaveButtons(this);
        });
    }

    disableFormSaveButtons(button) {
        button.setAttribute('disabled', true);
    }
}
