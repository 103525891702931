export default class DelProductItem extends HTMLButtonElement {
    constructor() {
        super();
    }

    connectedCallback() {
        $(this).on('click', function (e) {
            this.delProductItem();
        });
    }

    delProductItem() {
        $(this).parents('.product-item-block').remove();
    }
}