export default class SingleButtonFileInput extends HTMLInputElement {
    connectedCallback() {
        this.fileList = document.querySelector(`#${this.dataset.filesListId}`);
        this.watchers();
    }

    watchers() {
        this.addEventListener('change',(e) => {
            const fileList = e.target.files;
            this.printFiles(fileList);
        });
    }

    /**
     * Affiche la liste des documents chargés dans le champ this.fileList.
     * @param fileList
     */
    printFiles(fileList) {
        if (fileList.length === 0) {
            this.clearFiles();
            return;
        }

        // Constitue une chaîne avec les noms des documents séparés par des virgules
        let text = (fileList.length === 1) ? 'Fichier joint : ' : 'Fichiers joints : ';

        for (let i = 0; i < fileList.length; i++) {
            if (i > 0) {
                text += ', ';
            }
            text += fileList[i].name.replace(/^.*[\\\/]/, '');
        }

        // Met à jour le html (et le tooltip)
        this.fileList.innerHTML = text;
        this.fileList.setAttribute('title', text);
    }

    /**
     * Nettoie la liste des documents du champ this.fileList.
     */
    clearFiles() {
        // Vide le html (et le tooltip)
        this.fileList.innerHTML = '';
        this.fileList.setAttribute('title', '');
    }
}
