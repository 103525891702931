import {Modalator} from 'hegyd/core/resources/assets/js/utils/Modalator.js'

/**
 * Composant permettant d'ouvrir une modal dynamiquement suivant une url fournie.
 * Vous pouvez passer des méthodes à appeler en callback, pour cela vous devez préciser le container de la méthode ainsi que la méthode à appeler.
 *
 * Ex:
 * Un composant est sur la div avec l'id main-container et je dois appeler la méthode refreshView lorsque la modal a été soumise avec succès, voici l'appel:
 *  <a href="[URL]"
 is="core-utils-open-remote-modal"
 data-modal-target="#modal"
 data-callback-container-id="main-container"
 data-success-callback="refreshView">Mon bouton</a>
 */
export default class OpenRemoteModal extends HTMLAnchorElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.addEventListener('click', function (e) {
            e.preventDefault();

            let modalTarget = this.dataset.modalTarget;
            if (modalTarget == undefined) {
                modalTarget = '#modal';
            }

            let containerCallback = document;
            let afterLoadedCallback, successCallback, errorCallback;

            if (this.dataset.callbackContainerId) {
                containerCallback = document.getElementById(this.dataset.callbackContainerId);
            }

            if (this.dataset.afterLoadedCallback !== undefined) {
                if (this.dataset.afterLoadedCallback.indexOf("window") === 0) {
                    afterLoadedCallback = new Function(this.dataset.afterLoadedCallback);
                } else {
                    afterLoadedCallback = containerCallback[this.dataset.afterLoadedCallback];
                }
            }

            if (this.dataset.successCallback !== undefined) {
                if (this.dataset.successCallback.indexOf("window") === 0) {
                    successCallback = new Function(this.dataset.successCallback);
                } else {
                    successCallback = containerCallback[this.dataset.successCallback];
                }
            }

            if (this.dataset.errorCallback !== undefined) {
                if (this.dataset.errorCallback.indexOf("window") === 0) {
                    errorCallback = new Function(this.dataset.errorCallback);
                } else {
                    errorCallback = containerCallback[this.dataset.errorCallback];
                }
            }

            let ajaxSubmitForm = this.dataset.ajaxSubmitForm;

            var modal = new Modalator();

            modal.initRemoteModal(modalTarget, this.href, afterLoadedCallback, successCallback, errorCallback, ajaxSubmitForm);
        });
    }
}
