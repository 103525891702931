import 'leaflet';
import 'leaflet.markercluster';

export default class Map extends HTMLDivElement {

    constructor() {

        super();

        this.bounds = [];
        this.markers = [];
        this.cluster;
        this.map;

        this.DEFAULT_LAT = 46.71109;
        this.DEFAULT_LNG = 1.7191036;
        this.DEFAULT_ZOOM = 6;
        this.MAX_BOUNDS_ZOOM = 11;
    }

    connectedCallback() {
        this.initMap();
    }

    getMap(){
        return this.map;
    }

    initMap(){
        if(this.getMap() !== undefined) {
            this.getMap().remove();
        }

        var attr_osm = '&copy; <a href="http://openstreetmap.org/">OpenStreetMap</a> contributors';

        var osm = new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: attr_osm
        });

        this.map = new L.Map('map')
            .addLayer(osm)
            .setView([this.DEFAULT_LAT, this.DEFAULT_LNG], this.DEFAULT_ZOOM)
            .setMinZoom(2);
    }

    clearOverlays() {
        this.getMap().removeLayer(this.cluster);
    }

    addMarkers(items){
        if (this.markers.length > 0) {
            this.clearOverlays();
        }

        this.markers = [];
        this.bounds = [];
        this.cluster = L.markerClusterGroup();
        let self = this;
        items.forEach(function(value, key){
            var ratio = 100;
            var latitude = value["latitude"];
            var longitude = value["longitude"];
            var latlng = [latitude, longitude];
            var name = "Position";
            
            if(value['name'])
            {
                name = value['name'];
            }

            if (latitude && longitude){

                var icon = L.icon({
                    iconUrl: "/images/marker.png",
                });

                if(value["icon"] !== undefined){
                    icon = value["icon"]
                }

                var marker = L.marker(latlng, {
                    title: name,
                    icon: icon
                });

                if(value["content"])
                {
                    marker.bindPopup(value["content"]);
                }

                self.markers.push(marker);
                self.cluster.addLayer(marker);
                self.bounds.push(latlng);

                self.map.fitBounds(self.bounds);
            }
        });
        self.map.addLayer(self.cluster);

        self.adjustFitBounds();
    }

    addMarker(marker){
        this.markers.push(marker);
        this.cluster.addLayer(marker);

        var latlng = [marker._latlng.lat, marker._latlng.lng];
        this.bounds.push(latlng);

        this.map.fitBounds(this.bounds);
    }

    removeMarker(marker){
        const indexMarker = this.markers.indexOf(marker);
        this.markers.splice(indexMarker, 1);
        this.bounds.splice(indexMarker, 1);

        this.cluster.removeLayer(marker);
        this.map.fitBounds(this.bounds);
    }

    adjustFitBounds(){
        if (this.bounds.length > 0) {
            this.map.fitBounds(this.bounds);
        }

        if (this.map.getZoom() > this.MAX_BOUNDS_ZOOM) {
            this.map.setZoom(self.MAX_BOUNDS_ZOOM);
        }
    }
}