import {Modalator} from 'hegyd/core/resources/assets/js/utils/Modalator.js'

export default class SelectDecli extends HTMLSelectElement {

    constructor() {
        super();
    }
    
    connectedCallback() {
        $(this).on('change', (e) => {
            let href = $(this.options[e.target.selectedIndex]).data('href');
            if (href) {
                window.location.href = href;
            }
        });
    }
}