export default class RegisterForm extends HTMLFormElement {
    connectedCallback()
    {
        var emailAlreadyExists = document.getElementsByClassName('help-block')[3];
        const forgotPassword = document.getElementById('forgot-password-button');

        if(emailAlreadyExists.textContent === 'Un compte avec cet email existe déjà.'){
            emailAlreadyExists.classList.add('d-inline-block');
            forgotPassword.classList.remove('d-none');
        }

            $('#registerSubmitButton').on('click', () => {
                const lastnameElement = document.getElementById('lastname');
                const firstnameElement = document.getElementById('firstname');
                const emailElement = document.getElementById('email');
                const phoneElement = document.getElementById('phone');
                const activityElements = document.getElementsByName('activity');

                var lastnameError = $('#lastnameError');
                var firstnameError = $('#firstnameError');
                var emailError = $('#emailError');
                var phoneError = $('#phoneError');
                var activityError = $('#activityError');

                let errors = 0;

                lastnameError.text('');
                firstnameError.text('');
                emailError.text('');
                phoneError.text('');
                activityError.text('');

                if (firstnameElement && firstnameElement.value === '') {
                    firstnameError.text('Veuillez entrer votre prénom.');
                    if(!errors){
                        this.focusElement(firstnameElement);
                    }
                    ++errors;
                }
                if (lastnameElement && lastnameElement.value === '') {
                    lastnameError.text('Veuillez entrer votre nom.');
                    if(!errors){
                        this.focusElement(lastnameElement);
                    }
                    ++errors;
                }
                if (emailElement && emailElement.value === '') {
                    emailError.text('Veuillez entrer votre adresse e-mail.');
                    if(!errors){
                        this.focusElement(emailElement);
                    }
                    ++errors;
                }
                if (phoneElement && phoneElement.value === '') {
                    phoneError.text('Veuillez entrer votre numéro de téléphone.');
                    if(!errors){
                        this.focusElement(phoneElement);
                    }
                    ++errors;
                }
                let i = 0;
                activityElements.forEach(function (activityElement) {
                    var radioButton = document.getElementById('activity-'+activityElement.value);
                    if(radioButton.checked === false){
                        ++i;
                    }
                });
                if(i === activityElements.length){
                    activityError.text('Veuillez sélectionner votre type de profil.');
                    if(!errors){
                        this.focusElement(radioButton);
                    }
                    ++errors;
                }
                if(errors) {
                    return false;
                }
            });
    }

    focusElement(element)
    {
        element.focus();
    }
}
