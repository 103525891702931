export default class FaqQuestionToOpen extends HTMLDivElement {
    constructor() {
        super();
    }

    connectedCallback() {
        var hashCurrent = window.location.hash;

        if(this.id == hashCurrent.substr(1)){
            var collapsedBtn = this.querySelector('.accordion-button');

            if(collapsedBtn){
                collapsedBtn.classList.remove("collapsed");
                collapsedBtn.setAttribute('aria-expanded',true);

                var collapsedBlock = this.querySelector('.accordion-collapse');

                if(collapsedBlock){
                    collapsedBlock.classList.add('show');
                }
            }
        }
    }
}