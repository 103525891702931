export default class ManopNavigator extends HTMLUListElement {

    constructor() {
        super();
        this.$toggles = this.querySelectorAll('.js-toggle-sub-nav');
    }

    connectedCallback() {
        this.bind();
    }

    bind() {

        this.$toggles.forEach($toggle => {
            $toggle.addEventListener('click', (e) => {
                e.preventDefault();
                $toggle.parentElement.closest('li').classList.toggle("opened");
            })
        })

    }

}