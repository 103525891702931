export default class LinkToCart {
    constructor() {
        this.link = document.querySelector('#link-to-cart');
    }
    refresh() {
        if (this.link) {
            HegydCore.sendRequest(this.link.dataset.refreshUrl, 'GET', {}, (data) => {
                this.updateLinkToCart(data);
            })
        }
    }

    updateLinkToCart(data) {
        this.link.innerHTML = data;
    }
}
