export class Requestor {

    initAjaxRequestHeader() {
        $.xhrPool = []; // array of uncompleted requests
        $.xhrPool.abortAll = function () { // our abort function
            $(this).each(function (idx, jqXHR) {
                jqXHR.abort();
            });
            $.xhrPool.length = 0
        };
        var csrf_token = $('meta[name="csrf-token"]').attr('content');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': csrf_token
            },
            beforeSend: function (jqXHR) { // before jQuery send the request we will push it to our array
                $.xhrPool.push(jqXHR);
            },
            complete: function (jqXHR) { // when some of the requests completed it will splice from the array
                var index = $.xhrPool.indexOf(jqXHR);
                if (index > -1) {
                    $.xhrPool.splice(index, 1);
                }
            }
        });
    }

    sendRequest(url, method, data, callbackSuccess, callbackError, moreConfig) {
        let defaultConfig = {
            url: url,
            method: method,
            data: data,
            success: function (data) {
                if (callbackSuccess) {
                    callbackSuccess(data);
                }
            },
            error: function (data) {
                if (callbackError) {
                    callbackError(data);
                }
            }
        };

        if(moreConfig == undefined)
        {
            moreConfig = {};
        }

        let config = {...defaultConfig, ...moreConfig};

        $.ajax(config);
    }
}