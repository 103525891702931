import {debounce} from "debounce";

export default class CoreInfiniteScroll extends HTMLDivElement {

    constructor() {
        super();
        this.initEventScroll();
        this.initClickLoadMore();
        this.isLoading = false;
    }

    initClickLoadMore() {
        $(this).on('click', '.cta-load-more:not(.disabled)', (e) => {
                e.preventDefault();
                if (this.isLoading) {
                    return false;
                }
                this.isLoading = true;
                const $link = $(e.target).closest('a');
                HegydCore.sendRequest($link.attr("href"), "GET", {}, data => {
                    if (data.success == false) {
                        toastr.error('Une erreur s\'est produite.');
                    } else {
                        $(this).append(data.feed);
                        $(this).find('.more-items').html(data.more);
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 500);
                    }
                });
            }
        )
    }

    initEventScroll() {
        window.addEventListener('scroll', () => {
            const {scrollHeight, scrollTop, clientHeight} = document.documentElement;
            if (scrollTop + clientHeight > scrollHeight - 400) {
                if (this.isLoading) {
                    return true;
                }
                debounce($('.cta-load-more:not(.disabled)').trigger('click'), 200);
            }
        });
    }
}
