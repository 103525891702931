export default class ProductPackLight extends HTMLDivElement {

    constructor() {
        super();
    }

    connectedCallback() {
        if (this.parentElement.id === 'product-container') {
            this.updateTotal();
            this.productSelector();
            this.addedToCart();
        }
    }

    updateTotal() {
        const currentProductId = this.id.replace(/\D/g, '');
        const checkboxesOffcanvaAddCart = this.querySelectorAll(`#offcanvas-add-to-cart-${currentProductId} .checkbox`);
        const totalCountDisplayOffcanvaAddCart = this.querySelector(`#offcanvas-add-to-cart-${currentProductId} #total`);
        const productsDisplayOffcanvaAddCart = this.querySelector(`#offcanvas-add-to-cart-${currentProductId} #products`);
        const addToCartButtonOffcanvaAddCart = this.querySelector(`#offcanvas-add-to-cart-${currentProductId} #add-to-cart`);

        // Appliquez vos opérations sur les checkboxes ici
        if (typeof checkboxesOffcanvaAddCart !== 'undefined') {
            checkboxesOffcanvaAddCart.forEach(checkbox => {
                checkbox.addEventListener('change', updateTotal);
            });
        }

        // Fonction pour mettre à jour le total
        function updateTotal() {
            let totalCountOffcanvaAddCart = 0;
            if (typeof checkboxesOffcanvaAddCart !== 'undefined') {
                checkboxesOffcanvaAddCart.forEach((checkboxOffcanvaAddCart, index) => {
                    if (checkboxOffcanvaAddCart.checked) {
                        totalCountOffcanvaAddCart += 1;
                    }
                });
            }

            // Mettre à jour l'affichage du total
            totalCountDisplayOffcanvaAddCart.textContent = totalCountOffcanvaAddCart;

            if (totalCountOffcanvaAddCart <= 1) {
                productsDisplayOffcanvaAddCart.textContent = 'produit';
            } else {
                productsDisplayOffcanvaAddCart.textContent = 'produits';
            }
            if (totalCountOffcanvaAddCart <= 0) {
                addToCartButtonOffcanvaAddCart.classList.add('disabled');
            } else {
                addToCartButtonOffcanvaAddCart.classList.remove('disabled');
            }
        }

        updateTotal();
    }

    productSelector() {
        const currentProductId = this.id.replace(/\D/g, '');
        const checkboxesOffcanvaAddCart = this.querySelectorAll(`#offcanvas-add-to-cart-${currentProductId} .checkbox`);
        const addToCartButtonOffcanvaAddCart = this.querySelector(`#offcanvas-add-to-cart-${currentProductId} #add-to-cart`);
        const productCards = this.querySelectorAll(`#offcanvas-add-to-cart-${currentProductId} .product-pack-card`);
        const productCardsCheckboxes = this.querySelectorAll(`#offcanvas-add-to-cart-${currentProductId} .product-pack-card .checkbox`);

        const updateDataAttributes = () => {
            const selectedIdsOffcanvaAddCart = [];
            if (typeof checkboxesOffcanvaAddCart !== 'undefined') {
                checkboxesOffcanvaAddCart.forEach((checkbox, index) => {
                    if (checkbox.disabled) {
                        productCards[index].classList.add('d-none');
                        productCardsCheckboxes[index].classList.add('d-none');
                    }
                    if (checkbox.checked) {
                        const id = checkbox.value;
                        selectedIdsOffcanvaAddCart.push(id);
                    }
                });
            }

            addToCartButtonOffcanvaAddCart.removeAttribute('data-input-product-linked1-id');
            addToCartButtonOffcanvaAddCart.removeAttribute('data-input-product-linked2-id');

            if (selectedIdsOffcanvaAddCart.length > 0) {
                addToCartButtonOffcanvaAddCart.setAttribute('data-input-product-linked1-id', selectedIdsOffcanvaAddCart[0]);
            }
            if (selectedIdsOffcanvaAddCart.length > 1) {
                addToCartButtonOffcanvaAddCart.setAttribute('data-input-product-linked2-id', selectedIdsOffcanvaAddCart[1]);
            }

        };

        if (typeof checkboxesOffcanvaAddCart !== 'undefined') {
            checkboxesOffcanvaAddCart.forEach(checkbox => {
                checkbox.addEventListener('change', updateDataAttributes);
            });
        }

        updateDataAttributes();
    }

    addedToCart() {
        const currentProductId = this.id.replace(/\D/g, '');
        const addToCartButtonOffcanvaAddCart = this.querySelector(`#offcanvas-add-to-cart-${currentProductId} #add-to-cart`);
        const productCardsDiv = this.querySelector(`#offcanvas-add-to-cart-${currentProductId} .product-pack-cards-div`);
        const addedToCartDiv = this.querySelector(`#offcanvas-add-to-cart-${currentProductId} .added-to-cart`);
        const goToCartHeader = this.querySelector(`#offcanvas-add-to-cart-${currentProductId} .go-to-cart`);

        const addedToCart = () => {

            productCardsDiv.classList.add('d-none');
            goToCartHeader.classList.add('d-none');
            addedToCartDiv.classList.remove('d-none');

        };

        addToCartButtonOffcanvaAddCart.addEventListener('click', addedToCart);
    }
}
