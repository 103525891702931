export default function updateCartViews(data) {
    if (data.hasOwnProperty('templates')) {
        $.each(data.templates, (templateName, templateHtml) => {
            let $element = $('[data-cart-template="' + templateName + '"]', 'body');

            if ($element.length) {
                $element.children().remove();
                $element.append(templateHtml);

                if ($('.undeliverableAddress').length) {
                    swal({
                        type: 'error',
                        title: '',
                        html: true,
                        text: $('.undeliverableAddress').html()
                    });
                }
            } else if (templateName == 'cart-popup') {
                toastr.success(templateHtml);
            } else if (templateName == 'cart-popup-html') {
                $('[data-append-template="' + templateName + '"]')
                    .empty()
                    .append(templateHtml);
            }
        });
    }
}