import {debounce} from "debounce";

export default class FrontNewsIndex extends HTMLDivElement {

    constructor() {
        super();
        this.initEvents();
    }

    initEvents() {
        this.initKeyDownEvent();
    }

    runSearch() {
        var url = $(this).data('search-url');
        url += "?query=" + $(this).val();
        HegydCore.sendRequest(url, "GET", {}, data => {
            if (data.success == false) {
                toastr.error('Une erreur s\'est produite.');
            } else {
                $('.grid-news *:not(.more-items)').remove();
                $('.grid-news').append(data.feed);
                $('.grid-news').find('.more-items').html(data.more);
            }
        });
    }

    initKeyDownEvent() {
        $('.js-search-field').on('keydown', debounce(this.runSearch, 300));
    }
}