export default class AddProductButton extends HTMLButtonElement {
    constructor() {
        super();
    }

    connectedCallback() {
        $(this).on('click', function (e) {
            this.addProductItem();
        });
    }

    addProductItem() {
        let orderId = document.querySelector('select[name="order_id"]').value;
        let url = this.getAttribute('data-href');
        var excludedIds = [];

        $('select[name^="order_line_id"]').each(function(){
            excludedIds.push($(this).val());
        });

        HegydCore.sendRequest(url, 'POST', {id:orderId,excludedIds:excludedIds}, function (data) {
            if (data.hasOwnProperty('item')) {
                $('#products-list').append(data.item);

                $('.del-button').removeClass('d-none');

                $('.product-item-block:first .del-button').remove();
            }else{
                toastr.error(data.errorMessage);
            }

        },function (data) {
            if (data.hasOwnProperty('errorMessage')) {
                toastr.error(data.errorMessage);
            }
        });
    }
}
