export default class ProductPack extends HTMLDivElement {

    constructor() {
        super();
    }

    connectedCallback() {
        this.updateTotal();
        this.productSelector();
        this.showProductsOffcanvaAddToCart();
        this.changeVisibilityOffcanvasAddToCart();
        this.addedToCartOffcanva();
        this.addedToCart();
        this.synchroCheckboxTotals();
        this.addViewItemListDataLayer();
    }

    updateTotal() {
        const checkboxesMain = document.querySelectorAll('.section-product-pack .checkbox');
        const checkboxesOffcanvaAddCart = document.querySelectorAll('.offcanvas-add-to-cart .checkbox');
        const checkboxesOffcanvaBottom = document.querySelectorAll('.offcanvas-product-pack .checkbox');
        const totalCountDisplayMain = document.querySelector('.section-product-pack #total');
        const totalCountDisplayOffcanvaAddCart = document.querySelector('.offcanvas-add-to-cart #total');
        const totalCountDisplayOffcanvaBottom = document.querySelector('.offcanvas-product-pack #total');
        const totalCountDisplayOffcanvaBottomFakeButton = document.querySelector('.section-product-pack #total-responsive');
        const productsDisplayMain = document.querySelector('.section-product-pack #products');
        const productsDisplayOffcanvaAddCart = document.querySelector('.offcanvas-add-to-cart #products');
        const productsDisplayOffcanvaBottom = document.querySelector('.offcanvas-product-pack #products');
        const productsDisplayOffcanvaBottomFake = document.querySelector('.section-product-pack #products-responsive');
        const productPricesMain = document.querySelectorAll('.section-product-pack .product-price');
        const productPricesOffcanvaBottom = document.querySelectorAll('.offcanvas-product-pack .product-price');
        const totalPriceDisplayMain = document.querySelector('.section-product-pack #total-price');
        const totalPriceDisplayOffcanvaBottom = document.querySelector('.offcanvas-product-pack #total-price');
        const addToCartButtonMain = document.querySelector('.section-product-pack #add-to-cart');
        const addToCartButtonOffcanvaAddCart = document.querySelector('.offcanvas-add-to-cart #add-to-cart');
        const addToCartButtonOffcanvaBottom = document.querySelector('.offcanvas-product-pack #add-to-cart');
        const productCardsDiv = document.querySelector('.offcanvas-add-to-cart .product-pack-cards-div');
        const screenWidth = window.screen.width;
        const options = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        };

        // Fonction pour mettre à jour le total
        function updateTotal() {
            let totalCountMain = 1;
            let totalCountOffcanvaAddCart = 0;
            let totalCountOffcanvaBottom = 1;
            let totalPriceMain = parseFloat(productPricesMain[0].textContent.replace(/\s+/g, '').replace(/,/g, '.'));
            let totalPriceOffcanvaBottom = parseFloat(productPricesOffcanvaBottom[0].textContent.replace(/,/g, '.'));

            // Parcourir toutes les cases à cocher et compter celles qui sont cochées
            checkboxesMain.forEach((checkboxMain, indexMain) => {
                if (checkboxMain.checked && !checkboxMain.disabled) {
                    totalCountMain += 1;
                    if (!productCardsDiv.classList.contains('add-cart') && screenWidth >= 992) {
                        totalCountOffcanvaAddCart -= 1;
                    }
                    if (screenWidth >= 992) {
                        totalPriceMain += parseFloat(productPricesMain[indexMain+1].textContent.replace(/,/g, '.'));
                    }
                }
            });
            checkboxesOffcanvaAddCart.forEach((checkboxOffcanvaAddCart, index) => {
                if (checkboxOffcanvaAddCart.checked && !checkboxOffcanvaAddCart.disabled) {
                    totalCountOffcanvaAddCart += 1;
                }
            });

            checkboxesOffcanvaBottom.forEach((checkboxOffcanvaBottom, indexOffcanvaBottom) => {
                if (checkboxOffcanvaBottom.checked && !checkboxOffcanvaBottom.disabled) {
                    totalCountOffcanvaBottom += 1;
                    if (!productCardsDiv.classList.contains('add-cart') && screenWidth < 992) {
                        totalCountOffcanvaAddCart -= 1;
                    }
                    if (screenWidth < 992) {
                        totalPriceMain += parseFloat(productPricesMain[indexOffcanvaBottom+1].textContent.replace(/,/g, '.'));
                    }
                    totalPriceOffcanvaBottom += parseFloat(productPricesOffcanvaBottom[indexOffcanvaBottom+1].textContent.replace(/,/g, '.'));
                }
            });

            // Mettre à jour l'affichage du total
            totalCountDisplayMain.textContent = totalCountMain;
            totalCountDisplayOffcanvaAddCart.textContent = totalCountOffcanvaAddCart;
            totalCountDisplayOffcanvaBottom.textContent = totalCountOffcanvaBottom;
            totalCountDisplayOffcanvaBottomFakeButton.textContent = totalCountOffcanvaBottom;

            totalPriceDisplayMain.textContent = Number(totalPriceMain).toLocaleString('fr', options);
            totalPriceDisplayOffcanvaBottom.textContent = Number(totalPriceOffcanvaBottom).toLocaleString('fr', options);

            if (totalCountMain <= 1) {
                productsDisplayMain.textContent = 'produit';
            } else {
                productsDisplayMain.textContent = 'produits';
            }
            if (totalCountMain <= 0) {
                addToCartButtonMain.classList.add('disabled');
            } else {
                addToCartButtonMain.classList.remove('disabled');
            }

            if (totalCountOffcanvaAddCart <= 1) {
                productsDisplayOffcanvaAddCart.textContent = 'produit';
            } else {
                productsDisplayOffcanvaAddCart.textContent = 'produits';
            }
            if (totalCountOffcanvaAddCart <= 0) {
                addToCartButtonOffcanvaAddCart.classList.add('disabled');
            } else {
                addToCartButtonOffcanvaAddCart.classList.remove('disabled');
            }

            if (totalCountOffcanvaBottom <= 1) {
                productsDisplayOffcanvaBottom.textContent = 'produit';
                productsDisplayOffcanvaBottomFake.textContent = 'produit';
            } else {
                productsDisplayOffcanvaBottom.textContent = 'produits';
                productsDisplayOffcanvaBottomFake.textContent = 'produits';
            }
            if (totalCountOffcanvaBottom <= 0) {
                addToCartButtonOffcanvaBottom.classList.add('disabled');
            } else {
                addToCartButtonOffcanvaBottom.classList.remove('disabled');
            }
        }

        // Ajouter un écouteur d'événement à chaque case à cocher
        checkboxesMain.forEach(checkboxMain => {
            checkboxMain.addEventListener('change', updateTotal);
        });
        checkboxesOffcanvaAddCart.forEach(checkboxOffcanvaAddCart => {
            checkboxOffcanvaAddCart.addEventListener('change', updateTotal);
        });
        checkboxesOffcanvaBottom.forEach(checkboxOffcanvaBottom => {
            checkboxOffcanvaBottom.addEventListener('change', updateTotal);
        });

        addToCartButtonMain.addEventListener('click', updateTotal);
        addToCartButtonOffcanvaBottom.addEventListener('click', updateTotal);

        // Initialiser l'affichage du total
        updateTotal();
    }

    productSelector() {
        document.addEventListener('DOMContentLoaded', () => {
            const checkboxesMain = document.querySelectorAll('.section-product-pack .checkbox');
            const checkboxesOffcanvaAddCart = document.querySelectorAll('.offcanvas-add-to-cart .checkbox');
            const checkboxesOffcanvaBottom = document.querySelectorAll('.offcanvas-product-pack .checkbox');
            const addToCartButtonMain = document.querySelector('.section-product-pack #add-to-cart');
            const addToCartButtonOffcanvaAddCart = document.querySelector('.offcanvas-add-to-cart #add-to-cart');
            const addToCartButtonOffcanvaAddCartResponsive = document.querySelector('#offcanvas-product-pack #add-to-cart');
            const productCards = document.querySelectorAll('.offcanvas-add-to-cart .product-pack-card');
            const productCardsCheckboxes = document.querySelectorAll('.offcanvas-add-to-cart .product-pack-card .checkbox');
            const productCardsDiv = document.querySelector('.offcanvas-add-to-cart .product-pack-cards-div');
            const screenWidth = window.screen.width;

            const updateDataAttributes = () => {
                let allChecked = Array.from(checkboxesMain).filter(checkbox => !checkbox.disabled).every(checkbox => checkbox.checked);
                let allCheckedResponsive = Array.from(checkboxesOffcanvaBottom).filter(checkbox => !checkbox.disabled).every(checkbox => checkbox.checked);

                if (!productCardsDiv.classList.contains('add-cart')) {
                    if (allChecked && allCheckedResponsive) {
                        productCardsDiv.classList.add('d-none');
                    } else {
                        productCardsDiv.classList.remove('d-none');
                    }
                }

                const selectedIdsMain = [];
                checkboxesMain.forEach((checkbox, index) => {
                    if (checkbox.disabled) {
                        if (!productCardsDiv.classList.contains('add-cart') && screenWidth >= 992) {
                            productCards[index].classList.add('d-none');
                            productCardsCheckboxes[index].classList.add('d-none');
                        }
                    }
                    if (checkbox.checked) {
                        const id = checkbox.value;
                        selectedIdsMain.push(id);
                        if (!productCardsDiv.classList.contains('add-cart') && screenWidth >= 992) {
                            productCards[index].classList.add('d-none');
                            productCardsCheckboxes[index].classList.add('d-none');
                        }
                    } else {
                        if (screenWidth >= 992 && !checkbox.disabled) {
                            productCards[index].classList.remove('d-none');
                            productCardsCheckboxes[index].classList.remove('d-none');
                        }
                    }
                });

                const selectedIdsResponsive = [];
                checkboxesOffcanvaBottom.forEach((checkbox, index) => {
                    if (checkbox.disabled) {
                        if (!productCardsDiv.classList.contains('add-cart') && screenWidth < 992) {
                            productCards[index].classList.add('d-none');
                            productCardsCheckboxes[index].classList.add('d-none');
                        }
                    }
                    if (checkbox.checked) {
                        const id = checkbox.value;
                        selectedIdsResponsive.push(id);
                        if (!productCardsDiv.classList.contains('add-cart') && screenWidth < 992) {
                            productCards[index].classList.add('d-none');
                            productCardsCheckboxes[index].classList.add('d-none');
                        }
                    } else {
                        if (screenWidth < 992 && !checkbox.disabled) {
                            productCards[index].classList.remove('d-none');
                            productCardsCheckboxes[index].classList.remove('d-none');
                        }
                    }
                });

                const selectedIdsOffcanvaAddCart = [];
                checkboxesOffcanvaAddCart.forEach((checkbox, index) => {
                    if (checkbox.checked && !checkbox.classList.contains('d-none') && !checkbox.disabled) {
                        const id = checkbox.value;
                        selectedIdsOffcanvaAddCart.push(id);
                    }
                });

                addToCartButtonMain.removeAttribute('data-input-product-linked1-id');
                addToCartButtonMain.removeAttribute('data-input-product-linked2-id');

                addToCartButtonOffcanvaAddCart.removeAttribute('data-input-product-linked1-id');
                addToCartButtonOffcanvaAddCart.removeAttribute('data-input-product-linked2-id');

                addToCartButtonOffcanvaAddCartResponsive.removeAttribute('data-input-product-linked1-id');
                addToCartButtonOffcanvaAddCartResponsive.removeAttribute('data-input-product-linked2-id');

                // Set the attributes based on the selected IDs
                if (selectedIdsMain.length > 0) {
                    addToCartButtonMain.setAttribute('data-input-product-linked1-id', selectedIdsMain[0]);
                }
                if (selectedIdsMain.length > 1) {
                    addToCartButtonMain.setAttribute('data-input-product-linked2-id', selectedIdsMain[1]);
                }

                if (selectedIdsResponsive.length > 0) {
                    addToCartButtonOffcanvaAddCartResponsive.setAttribute('data-input-product-linked1-id', selectedIdsResponsive[0]);
                }
                if (selectedIdsResponsive.length > 1) {
                    addToCartButtonOffcanvaAddCartResponsive.setAttribute('data-input-product-linked2-id', selectedIdsResponsive[1]);
                }

                if (selectedIdsOffcanvaAddCart.length > 0) {
                    addToCartButtonOffcanvaAddCart.setAttribute('data-input-product-linked1-id', selectedIdsOffcanvaAddCart[0]);
                }
                if (selectedIdsOffcanvaAddCart.length > 1) {
                    addToCartButtonOffcanvaAddCart.setAttribute('data-input-product-linked2-id', selectedIdsOffcanvaAddCart[1]);
                }
            };

            checkboxesMain.forEach(checkbox => {
                checkbox.addEventListener('change', updateDataAttributes);
            });

            checkboxesOffcanvaAddCart.forEach(checkbox => {
                checkbox.addEventListener('change', updateDataAttributes);
            });

            checkboxesOffcanvaBottom.forEach(checkbox => {
                checkbox.addEventListener('change', updateDataAttributes);
            });

            // Initial update on page load
            updateDataAttributes();
        });
    }

    showProductsOffcanvaAddToCart() {
        document.addEventListener('DOMContentLoaded', () => {
            const productCards = document.querySelectorAll('.offcanvas-add-to-cart .product-pack-card');
            const productCardsDiv = document.querySelector('.offcanvas-add-to-cart .product-pack-cards-div');
            const addToCartButtonSingle = document.querySelector('#add_cart_boutton_wrapper #add-cart');
            const productCardsCheckboxes = document.querySelectorAll('.offcanvas-add-to-cart .product-pack-card .checkbox');
            const addToCartButtonOffcanvaAddCart = document.querySelector('.offcanvas-add-to-cart #add-to-cart');
            const checkboxesOffcanvaAddCart = document.querySelectorAll('.offcanvas-add-to-cart .checkbox');
            const totalCountDisplayOffcanvaAddCart = document.querySelector('.offcanvas-add-to-cart #total');
            const productsDisplayOffcanvaAddCart = document.querySelector('.offcanvas-add-to-cart #products');
            const addedToCartDiv = document.querySelector('.offcanvas-add-to-cart .added-to-cart');
            const goToCartHeader = document.querySelector('.offcanvas-add-to-cart .go-to-cart');

            const showProductsOffcanvaSingleAddToCart = () => {

                productCardsDiv.classList.remove('d-none');
                productCardsDiv.classList.add('add-cart');

                const validCheckboxesCount = Array.from(checkboxesOffcanvaAddCart).filter(checkbox => !checkbox.disabled).length

                if (validCheckboxesCount === 0) {
                    productCardsDiv.classList.add('d-none');
                }

                if(productCardsDiv.classList.contains('d-none')) {
                    setTimeout(() => {
                        addedToCartDiv.classList.remove('d-none');
                        goToCartHeader.classList.add('d-none');
                    }, "300");
                }

                if (validCheckboxesCount <= 1) {
                    productsDisplayOffcanvaAddCart.textContent = 'produit';
                } else {
                    productsDisplayOffcanvaAddCart.textContent = 'produits';
                }

                totalCountDisplayOffcanvaAddCart.textContent = validCheckboxesCount.toString();
                addToCartButtonOffcanvaAddCart.classList.remove('disabled');

                const selectedIdsOffcanvaAddCart = [];
                checkboxesOffcanvaAddCart.forEach((checkbox, index) => {
                    if (checkbox.disabled) {
                        productCards[index].classList.add('d-none');
                        productCardsCheckboxes[index].classList.add('d-none');
                    }
                    if (checkbox.checked && !checkbox.disabled) {
                        const id = checkbox.value;
                        selectedIdsOffcanvaAddCart.push(id);
                    }
                });

                if (selectedIdsOffcanvaAddCart.length > 0) {
                    addToCartButtonOffcanvaAddCart.setAttribute('data-input-product-linked1-id', selectedIdsOffcanvaAddCart[0]);
                }
                if (selectedIdsOffcanvaAddCart.length > 1) {
                    addToCartButtonOffcanvaAddCart.setAttribute('data-input-product-linked2-id', selectedIdsOffcanvaAddCart[1]);
                }

                productCards.forEach((productCard, index) => {
                    if (!checkboxesOffcanvaAddCart[index].disabled) {
                        productCard.classList.remove('d-none');
                        productCardsCheckboxes[index].classList.remove('d-none');
                    }
                });

            };

            addToCartButtonSingle.addEventListener('click', showProductsOffcanvaSingleAddToCart);

        });
    }

    changeVisibilityOffcanvasAddToCart() {
        document.addEventListener('DOMContentLoaded', () => {
            const addToCartOffcanvas = document.querySelector('.offcanvas-add-to-cart');
            const productCardsDiv = document.querySelector('.offcanvas-add-to-cart .product-pack-cards-div');
            const totalCountDisplayOffcanvaAddCart = document.querySelector('.offcanvas-add-to-cart #total');
            const addToCartButtonOffcanvaAddCart = document.querySelector('.offcanvas-add-to-cart #add-to-cart');
            const checkboxesOffcanvaAddCart = document.querySelectorAll('.offcanvas-add-to-cart .checkbox');
            const addedToCartDiv = document.querySelector('.offcanvas-add-to-cart .added-to-cart');
            const goToCartHeader = document.querySelector('.offcanvas-add-to-cart .go-to-cart');
            const checkboxesMain = document.querySelectorAll('.section-product-pack .checkbox');
            const checkboxesOffcanvaBottom = document.querySelectorAll('.offcanvas-product-pack .checkbox');
            const screenWidth = window.screen.width;

            const isVisible = (element) => {
                const style = window.getComputedStyle(element);
                return style.visibility !== 'hidden';
            };

            const onVisibilityChange = () => {
                if (isVisible(addToCartOffcanvas)) {
                    productCardsDiv.classList.remove('add-cart');
                    setTimeout(() => {
                        goToCartHeader.classList.remove('d-none');
                        addedToCartDiv.classList.add('d-none');
                        checkboxesOffcanvaAddCart.forEach((checkbox, index) => {
                            if (!checkbox.checked && !checkbox.disabled) {
                                checkbox.checked = true;
                            }
                        });
                    }, "300");
                }

                const validCheckboxesCount = Array.from(checkboxesOffcanvaAddCart).filter(checkbox => !checkbox.disabled && !checkbox.classList.contains('d-none')).length
                totalCountDisplayOffcanvaAddCart.textContent = validCheckboxesCount.toString();
                if (validCheckboxesCount > 0) {
                    addToCartButtonOffcanvaAddCart.classList.remove('disabled');
                }

                const selectedIdsOffcanvaAddCart = [];
                checkboxesOffcanvaAddCart.forEach((checkbox, index) => {
                    if (screenWidth >= 992 && !checkboxesMain[index].checked && !checkboxesMain[index].disabled) {
                        if (checkbox.checked && !checkbox.disabled) {
                            const id = checkbox.value;
                            selectedIdsOffcanvaAddCart.push(id);
                        }
                    }
                    if (screenWidth < 992 && !checkboxesOffcanvaBottom[index].checked && !checkboxesOffcanvaBottom[index].disabled) {
                        if (checkbox.checked && !checkbox.disabled) {
                            const id = checkbox.value;
                            selectedIdsOffcanvaAddCart.push(id);
                        }
                    }
                });

                if (selectedIdsOffcanvaAddCart.length > 0) {
                    addToCartButtonOffcanvaAddCart.setAttribute('data-input-product-linked1-id', selectedIdsOffcanvaAddCart[0]);
                }
                if (selectedIdsOffcanvaAddCart.length > 1) {
                    addToCartButtonOffcanvaAddCart.setAttribute('data-input-product-linked2-id', selectedIdsOffcanvaAddCart[1]);
                }
            };

            const observer = new MutationObserver((mutationsList) => {
                for (let mutation of mutationsList) {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                        onVisibilityChange();
                    }
                }
            });

            observer.observe(addToCartOffcanvas, { attributes: true });

            // Initial check in case the element is already visible on load
            onVisibilityChange();
        });
    }

    addedToCartOffcanva() {
        document.addEventListener('DOMContentLoaded', () => {
            const addToCartButtonOffcanvaAddCart = document.querySelector('.offcanvas-add-to-cart #add-to-cart');
            const productCardsDiv = document.querySelector('.offcanvas-add-to-cart .product-pack-cards-div');
            const addedToCartDiv = document.querySelector('.offcanvas-add-to-cart .added-to-cart');
            const goToCartHeader = document.querySelector('.offcanvas-add-to-cart .go-to-cart');

            const addedToCartOffcanva = () => {

                productCardsDiv.classList.add('d-none');
                goToCartHeader.classList.add('d-none');
                addedToCartDiv.classList.remove('d-none');

            };

            addToCartButtonOffcanvaAddCart.addEventListener('click', addedToCartOffcanva);

        });
    }

    addedToCart() {
        document.addEventListener('DOMContentLoaded', () => {
            const addToCartButtonMain = document.querySelector('.section-product-pack #add-to-cart');
            const addToCartButtonOffcanvaBottom = document.querySelector('.offcanvas-product-pack #add-to-cart');
            const productCardsDiv = document.querySelector('.offcanvas-add-to-cart .product-pack-cards-div');
            const addedToCartDiv = document.querySelector('.offcanvas-add-to-cart .added-to-cart');
            const goToCartHeader = document.querySelector('.offcanvas-add-to-cart .go-to-cart');
            const totalCountDisplayOffcanvaAddCart = document.querySelector('.offcanvas-add-to-cart #total');
            const productsDisplayOffcanvaAddCart = document.querySelector('.offcanvas-add-to-cart #products');
            const addToCartButtonOffcanvaAddCart = document.querySelector('.offcanvas-add-to-cart #add-to-cart');

            const addedToCart = () => {
                if (totalCountDisplayOffcanvaAddCart.textContent <= 0) {
                    productCardsDiv.classList.add('d-none')
                }

                if(productCardsDiv.classList.contains('d-none')) {
                    setTimeout(() => {
                        addedToCartDiv.classList.remove('d-none');
                        goToCartHeader.classList.add('d-none');
                    }, "300");
                }

                if (totalCountDisplayOffcanvaAddCart.textContent <= 1) {
                    productsDisplayOffcanvaAddCart.textContent = 'produit';
                } else {
                    productsDisplayOffcanvaAddCart.textContent = 'produits';
                }
                if (totalCountDisplayOffcanvaAddCart.textContent <= 0) {
                    addToCartButtonOffcanvaAddCart.classList.add('disabled');
                } else {
                    addToCartButtonOffcanvaAddCart.classList.remove('disabled');
                }

            };

            addToCartButtonMain.addEventListener('click', addedToCart);
            addToCartButtonOffcanvaBottom.addEventListener('click', addedToCart);

        });
    }

    synchroCheckboxTotals() {
        window.addEventListener('pageshow', () => {
            const checkboxesMain = document.querySelectorAll('.section-product-pack .checkbox');
            const checkboxesOffcanvaBottom = document.querySelectorAll('.offcanvas-product-pack .checkbox');
            const screenWidth = window.screen.width;

            if (screenWidth < 992) {
                checkboxesOffcanvaBottom.forEach((checkboxOffcanvaBottom) => {
                    if (!checkboxOffcanvaBottom.disabled) {
                        checkboxOffcanvaBottom.checked = true;
                    }
                });
            }

            if (screenWidth >= 992) {
                checkboxesMain.forEach((checkboxMain) => {
                    if (!checkboxMain.disabled) {
                        checkboxMain.checked = true;
                    }
                });
            }

            this.updateTotal();
        });

    }

    addViewItemListDataLayer() {
        window.addEventListener('load', () => {
            let dataLayerViewItem = JSON.parse($(this).find('.datalayer-view-item').html());

            if (dataLayerViewItem.ecommerce.items[0].length !== 0) {
                dataLayerViewItem.ecommerce.items[0].forEach(function(item) {
                    delete item.product_id;
                    item.item_list_name = 'pack.products.sheet';
                });

                dataLayerViewItem.ecommerce.items = dataLayerViewItem.ecommerce.items.flat();

                dataLayer.push(dataLayerViewItem);
            }
        });
    }
}
