export default class SavProductChange extends HTMLSelectElement {
    connectedCallback() {
        $(this).on('change', () => {
            this.requestQuantities();
        });
    }

    requestQuantities() {
        const orderId = document.querySelector('select[name="order_id"]').value;
        const url = this.getAttribute('data-href');
        const quantityField = $(this).parents('.product-item-block').find('.quantity-field');

        if (quantityField.length === 0) {
            return;
        }

        if ( ! this.value || this.value === "") {
            quantityField[0].value = 0;
            quantityField.attr('max', null);
            quantityField.attr('disabled', true);
            quantityField[0].dispatchEvent(new Event('change'));
            return;
        }

        HegydCore.sendRequest(
            url,
            'POST',
            {
                orderLineId: this.value,
                orderId: orderId
            },
            (data) => {
                if (data.hasOwnProperty('quantity')) {
                    quantityField.val(quantityField.attr('min') || 0);
                    quantityField.attr('max', data.quantity);
                    quantityField.attr('disabled', false);
                    quantityField[0].dispatchEvent(new Event('change'));

                    $('#add-button-block').removeClass('d-none');
                } else {
                    toastr.error(data.errorMessage);
                }
            },
            (data) => {
                if (data.hasOwnProperty('errorMessage')) {
                    toastr.error(data.errorMessage);
                }
            }
        );
    }
}
