export default class ProductFilter extends HTMLDivElement {
    constructor() {
        super();
    }

    connectedCallback() {
        console.log(this);
        this.initFilter();
    }

    initFilter(){
        /**
         * Differents templates reloadable
         * @type {string}
         */
        this.TEMPLATE_PRODUCTS = 'products'
        this.TEMPLATE_CATEGORIES = 'categories'

        /**
         * Containers
         * @type {string}
         */
        this.productsContainerId = '#products-container'
        this.categoriesContainerId = '#categories-container'

        /**
         * Current selected filters
         * @type {{}}
         */
        this.selectedFilters = {}

        /**
         * Full hash value (with hashtag #)
         * @type {string}
         */
        this.hashCurrent = null

        /**
         * Hash value without # (hashtag)
         * Used as key in javascript object to store/recover cache
         * @type {string}
         */
        this.hashKey = null

        /**
         * To store already retrieved content
         * @type {{object}}
         */
        this.cache = {}

        /**
         * Default product filters values
         * @type {number}
         */
        this.productsPage = 1
        this.productsQuantity = 12
        this.productsOrder = 1

        this.categoriesPage = 1
        this.categoriesQuantity = 12
        this.categoriesOrder = 1

        /**
         * Regex
         */
        this.productsPageRegex = /\#?\&?p=(\d+)/i
        this.productsQuantityRegex = /\#?\&?q=(\d+)/i
        this.productsOrderRegex = /\#?\&?t=(\d+)/i

        this.categoriesPageRegex = /\#?\&?pc=(\d+)/i
        this.categoriesQuantityRegex = /\#?\&?qc=(\d+)/i
        this.categoriesOrderRegex =  /\#?\&?tc=(\d+)/i

        this.bind();
    }

    bind() {
        this.initEvents();
        this.updateContentFromHash();
    }

    /**
     * Return jQuery container
     * @param containerId
     * @returns {*|jQuery|HTMLElement}
     */
    getContainer(containerId) {
        const $container = $(containerId);

        if ($container !== undefined && $container.length > 0)
            return $container;
        else {
            console.error('Container not found - Id : ' + containerId);
            return undefined;
        }
    }

    getProductsContainer() {
        return this.getContainer(this.productsContainerId);
    }

    getCategoriesContainer() {
        return this.getContainer(this.categoriesContainerId);
    }

    /**
     * Analyse hash, and update content with hash params
     */
    updateContentFromHash() {
        this.hashCurrent = window.location.hash;
        this.hashKey = this.hashCurrent.replace(/^#/, '');

        // Update current page from current hash
        this.updatePaginationFromHash();

        this.updateQuantityFromHash();

        this.updateOrderFromHash();

        this.getCurrentProductsContent();
    }

    /**
     * Update CurrentPage using current URL hash
     */
    updatePaginationFromHash() {
        let match;

        if (match = this.productsPageRegex.exec(this.hashCurrent)) {
            // Update the products current page
            this.productsPage = match[1];
        }

        if (match = this.categoriesPageRegex.exec(this.hashCurrent)) {
            // Update the categories current page
            this.categoriesPage = match[1];
        }
    }

    /**
     * Update Quantity using current URL hash
     */
    updateQuantityFromHash() {
        let match;

        if (match = this.productsQuantityRegex.exec(this.hashCurrent)) {
            // Update the products current page
            this.productsQuantity = match[1];
        }

        if (match = this.categoriesQuantityRegex.exec(this.hashCurrent)) {
            // Update the categories current page
            this.categoriesQuantity = match[1];
        }
    }

    /**
     * Update Order using current URL hash
     */
    updateOrderFromHash() {
        let match;

        if (match = this.productsOrderRegex.exec(this.hashCurrent)) {
            // Update the products current page
            this.productsOrder = match[1];
        }

        if (match = this.categoriesOrderRegex.exec(this.hashCurrent)) {
            // Update the categories current page
            this.categoriesOrder = match[1];
        }
    }

    /**
     * Retrieve the content adapted to the current selected filters
     */
    getCurrentProductsContent(templates) {
        if (typeof templates === 'undefined') {
            templates = [
                this.TEMPLATE_PRODUCTS,
                this.TEMPLATE_CATEGORIES,
            ];
        }

        // Check if cache content exist
        if (this.isCurrentFilterCached(templates)) {
            // If it does, then show the cached content
            this.updateContent(templates);
        } else {
            // If not, then request for content and cache it
            this.sendFilterRequest(templates);
        }
    }

    /**
     * Check if system has already load the current requested hashKey
     * @returns {boolean}
     */
    isCurrentFilterCached(templates) {
        let isCached = true;

        if (!this.cache.hasOwnProperty(this.hashKey)) {
            this.cache[this.hashKey] = {};
            isCached = false;
        }

        $.each(this.cache[this.hashKey], function (key, item) {
            if (isCached === false)
                return;

            isCached = $.inArray(key, templates) > -1;
        });

        return isCached;
    }

    /**
     * Save retrieved data into cache object
     * @param content
     * @param templates
     */
    pushCachedData(content, templates) {
        var self = this;

        if (!self.cache.hasOwnProperty(this.hashKey)) {
            self.cache[self.hashKey] = {};
        }

        $.each(templates, function (key, value) {
            if (content.templates.hasOwnProperty(value)) {
                self.cache[self.hashKey][value] = content.templates[value];
            }
        });
    }

    /**
     * Update the content of the page with current hashKey
     */
    updateContent() {
        let data;
        var self = this;

        if (data = this.cache[this.hashKey]) {
            $.each(data, function (key, item) {
                let $container = undefined;

                if (key === self.TEMPLATE_PRODUCTS) {
                    $container = self.getProductsContainer();
                } else if (key === self.TEMPLATE_CATEGORIES) {
                    $container = self.getCategoriesContainer();
                }

                if (typeof $container !== 'undefined') {
                    $container.empty().append($(item));
                }
            });
        }

        // Reload listener on favourites stars click
        // App.initFavourites();
    }

    /**
     * Update hash class params and propagate the new hash into the URL
     */
    updateHash() {
        this.hashKey = '1';

        /*
         * Hash for products
         */
        this.hashKey += '&p=' + this.productsPage;
        this.hashKey += '&q=' + this.productsQuantity;
        this.hashKey += '&t=' + this.productsOrder;

        /*
         * Hash for categories
         */
        this.hashKey += '&pc=' + this.categoriesPage;
        this.hashKey += '&qc=' + this.categoriesQuantity;
        this.hashKey += '&tc=' + this.categoriesOrder;

        window.location.hash = '#' + this.hashKey;
    }

    /**
     * Build current url
     * @returns {string}
     */
    buildUrl() {
        let url = window.location.origin + window.location.pathname;

        let search = window.location.search;

        if (!search) {
            search = '?1';
        }

        url += search;

        return url;
    }

    /**
     * Send request with template required to response,
     * filters, pages, orders, quantities
     * @param templates Templates required to json response
     */
    sendFilterRequest(templates) {
        var self = this;
        let $container;

        $.each(templates, function (key, value) {
            if (value === this.TEMPLATE_PRODUCTS) {
                $container = this.getProductsContainer();
            } else if (value === this.TEMPLATE_CATEGORIES) {
                $container = this.getCategoriesContainer();
            }
        });

        if (typeof $container !== 'undefined') {
            $container.empty().append($('.loader-template').clone().children());
        }

        $.ajax({
            url: this.buildUrl(),
            data: {
                templates: templates,
                p: this.productsPage,
                q: this.productsQuantity,
                t: this.productsOrder,
                pc: this.categoriesPage,
                qc: this.categoriesQuantity,
                tc: this.categoriesOrder,
            },
            success: function (data) {
                // Save retrieved data to cache array
                self.pushCachedData(data, templates);
                // Then update content
                self.updateContent();
            }
        });
    }

    /**
     * Update hash url and products contents
     * @param templates
     */
    refreshHashAndContent(templates) {
        this.updateHash();
        this.getCurrentProductsContent(templates);
    }

    /**
     * Init all page events
     */
    initEvents() {
        this.initBlockEvents(this.TEMPLATE_PRODUCTS);
        this.initBlockEvents(this.TEMPLATE_CATEGORIES);
    }

    /**
     * Init products events
     *
     * @param template
     * @returns {boolean}
     */
    initBlockEvents(template) {
        var self = this;
        let $container = undefined;

        if (template === this.TEMPLATE_PRODUCTS) {
            $container = this.getProductsContainer();
        } else if (template === this.TEMPLATE_CATEGORIES) {
            $container = this.getCategoriesContainer();
        }

        if (typeof $container === 'undefined') {
            return false;
        }

        let templates = [template];

        $container.on('click', '.pagination a', function (e) {
            e.preventDefault();

            const page = $(this).attr('href').split('page=')[1];

            if (template === self.TEMPLATE_PRODUCTS) {
                self.productsPage = page;
            } else if (template === self.TEMPLATE_CATEGORIES) {
                self.categoriesPage = page;
            }

            self.refreshHashAndContent(templates);

            return false;
        })
            .on('change', '.filter-order', function (e) {
                e.preventDefault();

                const order = $(this).val();

                if (template === self.TEMPLATE_PRODUCTS) {
                    self.productsOrder = order;
                } else if (template === self.TEMPLATE_CATEGORIES) {
                    self.categoriesOrder = order;
                }

                self.refreshHashAndContent(templates);

                return false;
            })
            .on('change', '.filter-quantity', function (e) {
                e.preventDefault();

                const quantity = $(this).val();

                if (template === self.TEMPLATE_PRODUCTS) {
                    self.productsPage = 1;
                    self.productsQuantity = quantity;
                } else if (template === self.TEMPLATE_CATEGORIES) {
                    self.categoriesPage = 1;
                    self.categoriesQuantity = quantity;
                }

                self.refreshHashAndContent(templates);

                return false;
            });

    }
}
