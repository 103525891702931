export default class BlocActus extends HTMLDivElement {

    constructor() {
        super();
    }

    connectedCallback() {
        // Fix la taille des description d'actus à la celle qui est la plus haute.
        document.addEventListener("DOMContentLoaded", () => {
            const descs = document.querySelectorAll('.description');
            var highest = 0;
            descs.forEach( element => {
                if(element.offsetHeight > highest) {
                    highest = element.offsetHeight;
                }
            });
            descs.forEach( element => {
                element.style.height = `${highest}px`;
            });
        });
    }
}
