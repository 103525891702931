import PhotoSwipe from 'photoswipe';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

export default class ProductImagePhotoswipe extends HTMLDivElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.lightbox = new PhotoSwipeLightbox({
          gallery: '#product-image-viewer-wrapper',
          children: 'a',
          pswpModule: () => PhotoSwipe
        });
        this.lightbox.init();
    }
}
