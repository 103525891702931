require('toastr');
require('hegyd/core/resources/assets/js/hegyd-core.js');
require('../../../../../frontoffice/js/hegyd-gtm.js');

import LinkToCart from "../../../ecommerce-v2/js/frontoffice/LinkToCart";

export default class AddToCart extends HTMLButtonElement {
    connectedCallback() {
        // Champ Quantité
        this.inputQuantity = document.querySelector(this.dataset.inputQuantityId);
        this.linkToCart = new LinkToCart();
        // Événements
        this.initEvents();
    }

    /**
     * Événements.
     */
    initEvents() {
        // Click sur le boutton d'ajout au panier
        this.addEventListener('click', (e) => {
            e.preventDefault();
            if ( ! this.disabled) {
                this.addToCart();
            }
        }, true);
    }

    /**
     * Lance la requête d'ajout au panier.
     */
    addToCart() {
        let dataLayerDatas = JSON.parse($(this).find('.datalayer-data').html());
        let dataLayerViewItem = JSON.parse($(this).find('.datalayer-view-item').html());
        let dataLayerViewItemCarousel = JSON.parse($(this).find('.datalayer-view-item-carousel').html());
        let qte = this.getCartQuantity();
        let productId = this.getProductId();

        dataLayerDatas.ecommerce.items[0].quantity = qte;

        window.dataLayer = window.dataLayer || [];

        dataLayer.push({ ecommerce: null });
        dataLayer.push(dataLayerDatas);

        if (dataLayerViewItem.ecommerce.items[0].length !== 0) {
            dataLayerViewItem.ecommerce.items[0].forEach(function(item) {
                delete item.product_id;
            });
        }

        if (dataLayerViewItemCarousel.ecommerce.items[0].length !== 0) {
            dataLayerViewItemCarousel.ecommerce.items[0].forEach(function(item) {
                delete item.product_id;
            });
        }

        dataLayerViewItem.ecommerce.items = dataLayerViewItem.ecommerce.items.flat();
        dataLayerViewItemCarousel.ecommerce.items = dataLayerViewItemCarousel.ecommerce.items.flat();

        if ((dataLayerViewItem.ecommerce.items[0] && dataLayerViewItem.ecommerce.items[0].length !== 0)
            && (dataLayerViewItemCarousel.ecommerce.items[0] && dataLayerViewItemCarousel.ecommerce.items[0].length !== 0)) {

            const mergedData = {
                "page": dataLayerViewItem.page,
                "event": dataLayerViewItem.event,
                "ecommerce": {
                    "items": dataLayerViewItem.ecommerce.items.concat(dataLayerViewItemCarousel.ecommerce.items)
                }
            };

            dataLayer.push(mergedData);
        } else if (dataLayerViewItemCarousel.ecommerce.items.flat().length === 0 && dataLayerViewItem.ecommerce.items.flat().length !== 0) {
            dataLayer.push(dataLayerViewItem);
        } else if (dataLayerViewItem.ecommerce.items.flat().length === 0 && dataLayerViewItemCarousel.ecommerce.items.flat().length !== 0) {
            dataLayer.push(dataLayerViewItemCarousel);
        }

        HegydCore.sendRequest(this.dataset.href, 'POST', {
            product_id: this.getProductId(),
            quantity: this.getCartQuantity(),
            // return_templates: ['cart-header']
        }, () => {
            this.refreshCartIcon();
        });

        if (this.parentElement.id === 'group-item-add-to-cart') {
            fetch('/load-offcanvas/' + productId, {
                method: 'GET',
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    const offcanvasDiv = document.querySelector('.product-group-item-div');
                    offcanvasDiv.innerHTML = data['offcanvas-light'];
                    let offcanvas = document.querySelector(`#offcanvas-add-to-cart-${productId}`);

                    if (offcanvas) {
                        const bsOffcanvas = new bootstrap.Offcanvas(offcanvas);
                        bsOffcanvas.show();
                    } else {
                        console.error('Offcanvas élément non trouvé');
                    }
                })
                .catch(error => {
                    // Handle error
                    console.log(error);
                });
        }
    }

    /**
     * Quantité à ajouter au panier.
     */
    getCartQuantity() {
        return parseInt(this.inputQuantity && this.inputQuantity.value ? this.inputQuantity.value : 0);
    }

    /**
     * Identifiant du produit à ajouter au panier.
     */
    getProductId() {
        return this.inputQuantity ? this.inputQuantity.dataset.product_id : null;
    }

    refreshCartIcon() {
        this.linkToCart.refresh();
    }
}
