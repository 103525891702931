import 'hegyd/core/resources/assets/dependencies/jquery-form/jquery.form';

/**
 * Format d'appel  :
 *  var modal = new Modalator();
 *  modal.initRemoteModal('modal', url);
 */
export class Modalator {
    initRemoteModal (selector, remoteUrl, afterLoadedCallback, successCallback, errorCallback, ajaxSubmitForm) {
        var modalElement = document.querySelector(selector);
        var self = this;

        if (!modalElement) {
            console.error('Modal not found !');

            return false;
        }

        var modal = new bootstrap.Modal(modalElement, {});

        if (ajaxSubmitForm === undefined) {
            ajaxSubmitForm = true;
        }

        modalElement.addEventListener('hidden.bs.modal', function (e) {
            let target = $(e.target);

            $(target).find('.modal-content:not(.static-content)').empty();
        });

        $('.modal-content', selector).load(remoteUrl, function () {
            if (afterLoadedCallback != undefined) {
                afterLoadedCallback();
            }

            if (ajaxSubmitForm) {
                var $form = $(modalElement).find('form');
                var $loader = $(modalElement).find('.loading');

                if (!$.fn.ajaxForm) {
                    return;
                }

                var ajaxConfig = {
                    beforeSubmit: function (arr, $form, option) {
                        if ($form.attr('data-confirm') !== undefined && $form.attr('data-confirm') !== 'confirmed') {
                            swal({
                                animation: false,
                                customClass: 'animated fadeIn',
                                title: $form.attr("data-swal-title"),
                                text: $form.attr("data-swal-text"),
                                type: $form.attr("data-swal-type"),
                                showCancelButton: true,
                                confirmButtonText: 'Oui',
                                cancelButtonText: 'Non'
                            }).then(function (result) {
                                if (result.value) {
                                    // En cas de confirmation, on submit le formulaire normalement.
                                    // $form.removeAttr('data-confirm');
                                    $form.attr('data-confirm', 'confirmed');
                                    // var $form = modal.find('form');
                                    $form.submit();
                                }
                            });

                            return false;
                        }

                        $('form', modalElement).addClass('hide');
                        $loader.removeClass('hide');
                    },
                    success: function (data) {
                        modal.hide();

                        if (successCallback != undefined) {
                            successCallback(data);
                        }
                    },
                    error: function (data, error, msg) {
                        if (errorCallback != undefined) {
                            errorCallback(data);
                        }

                        $loader.addClass('hide');
                        $('form', modalElement).removeClass('hide');

                        var $error = $('<div/>').addClass('alert alert-block alert-danger')
                            .html('' +
                                '<button data-dismiss="alert" class="close close-sm" type="button">' +
                                '   <i class="uil uil-times"></i>' +
                                '</button>' + self.getModalOutputData(data.responseJSON)
                            );

                        $form.prepend($error);
                    }
                };

                $form.ajaxForm(ajaxConfig);
            }
        });

        modal.show();
    }

    getModalOutputData (data) {
        if (typeof data !== 'undefined') {
            var list = data;

            if (data.hasOwnProperty('errors'))
                list = data.errors;

            var html = '<ul>';

            $.each(list, function (key, item) {
                html += '<li>' + item + '</li>';
            });

            html += '</ul>';

            return html;
        }

        return 'Un problème est survenu lors de l\'enregistrement';
    }
}