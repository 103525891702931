export default class FrontNewsLine extends HTMLAnchorElement {

    constructor() {
        super();
        this.initEvents();
    }

    initEvents() {
        
        $(this).on('click', function(e){
            e.preventDefault();

            var $this = $(this);
            var $like = $this.data('like');
            var $id = $this.data('id');

            $.ajax({
                url: $this.attr('href'),
                data: {
                    'like_status' : $like
                },
                method: 'PUT',
                loader: false,
                success: function (data) {
                    if (data.success == false) {
                        toastr.error('Une erreur s\'est produite.');
                    } else if (data.hasOwnProperty('view')) {
                        $('.like-container').replaceWith(data.view);
                    }
                }
            });
        })
    }
}