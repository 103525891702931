"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// jQuery
const jQuery = require("jquery");
window.jQuery = jQuery;
window.$ = jQuery;
// Bootstrap
const bootstrap = require("bootstrap");
window.bootstrap = bootstrap;
// Autres dépendances
require("@ungap/custom-elements");
window.toastr = require('toastr');
const sweetalert2_1 = require("sweetalert2");
window.swal = sweetalert2_1.default;
// Modules
require("../../vendor/plomberie-pro-main/src/js/main.js");
require("../../vendor/hegyd/products/js/main-fo.js");
require("../../vendor/hegyd/ecommerce-v2/js/main-fo.js");
require("hegyd/sav/resources/assets/js/main-fo.js");
// Module CMS
require('hegyd/cms/resources/assets/js/main.js');
// Components
require("./components.js");
require("hegyd/core/resources/assets/js/components/Utils/main.js");
//Pages
require("./pages.js");
// JS de l'intégration
require("../../vendor/plomberie-pro-main/src/js/main.js");
// Module NewsV2
require('hegyd/news-v2/resources/assets/js/frontend/main.js');
// Module FAQ
require('hegyd/faq/resources/assets/js/main-fo.js');
