export default class StarRating extends HTMLDivElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.setStars();

        this.querySelectorAll('.star-rate').forEach(element => {
            element.addEventListener('click', e => {
                e.preventDefault();
                let {target} = e;
                var oldValue = target.parentNode.getRating();

                this.setRating(target.dataset.rating);
                this.setStars(oldValue);
            });
        });
    }

    setStars(oldValue) {
        this.querySelectorAll('.star-rate').forEach(element => {
            var $starRating = $(element);

            var currentRating = this.getRating();

            if(!oldValue)
            {
                oldValue = 0;
            }

            // Permet de cocher 0 étoile
            if(currentRating == oldValue && oldValue == 1)
            {
                currentRating = 0;
                this.setRating(currentRating);
            }

            if (currentRating >= parseInt($starRating.data('rating'))) {
                return $starRating.removeClass(['uil', 'uil-star']).addClass(['uis', 'uis-star']);
            } else {
                return $starRating.removeClass(['uis', 'uis-star']).addClass(['uil', 'uil-star']);
            }
        });
    }

    getRating() {
        return parseInt(this.querySelector('input.rating-value').value);
    }

    setRating(rating){
        this.querySelector('input.rating-value').value = parseInt(rating);
        this.querySelector('input.rating-value').dispatchEvent(new Event('change', {bubbles: true}));

    }
}