export default class ArticleCmsIndex extends HTMLDivElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.initArtIndex();
    }

    initArtIndex() {
        this.addEventListener('load',this.onload());

        window.refreshView = function (item, data) {
            window.location.reload();
        };
    }

    onload() {
        let route = $("ol[data-parent]").data('route');

        $("ol[data-parent]").sortable({
            axis: "y",
            stop: function( ) {
                let order = $(this).find("li").map(function() {
                    return $(this).attr("data-id");
                }).get().join();

                $.post(route, {
                    'parent': $(this).attr("data-parent"),
                    "order": order
                }, function(answer) {
                    toastr.success('L\'ordre des articles a bien été mis à jour');
                });
            }
        });

        $("ol[data-parent]").disableSelection();

        let orderParentRoute = $('.cat-title').data('route');

        $('.srt-content').sortable({
            axis: 'y',
            stop: function () {
                let order = $('.admin-cms-articles').find(".cat-title").map(function() {
                    return $(this).attr("data-id");
                }).get().join();

                $.post(orderParentRoute, {
                    "order": order
                }, function(answer) {
                    toastr.success('L\'ordre des articles a bien été mis à jour');
                });
            }
        });
    };
}